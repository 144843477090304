




































import { Component, Vue } from "vue-property-decorator";
import Seller from "@/components/fragments/Seller.vue";
import Buyer from "@/components/fragments/Buyer.vue";
import InvoiceDetail from "@/components/fragments/InvoiceDetail.vue";
import Positions from "@/components/fragments/Positions.vue";
import Allowance from "@/components/fragments/Allowance.vue";
import Tax from "@/components/fragments/Tax.vue";
import TaxNew from "@/components/fragments/TaxNew.vue";
import Attachments from "@/components/fragments/Attachments.vue";
import RegisterDialog from "@/components/dialogs/RegisterDialog.vue";
import InvoicePreviewDialog from "@/components/dialogs/InvoicePreviewDialog.vue";
import Dialog from "@/components/elements/Dialog.vue";
import axios from "axios";
import Totals from "@/components/fragments/Totals.vue";
import Payment from "@/components/fragments/Payment.vue";
import CardGroup from "@/components/elements/CardGroup.vue";
import Modal from "@/components/elements/Modal.vue";
import { APIURL } from "../../store/globals";

/**
 * Renders the complete form
 * 
 * Events:
 * - disable-download-button = this.$root.$emit('disable-download-button')
 * - enable-download-button = this.$root.$emit('enable-download-button')
 * - reset-form = this.$root.$emit('reset-form')
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: {
    CardGroup,
    Payment,
    Totals,
    RegisterPopup: RegisterDialog,
    Seller,
    Buyer,
    InvoiceDetail,
    Positions,
    Allowance,
    Tax,
    TaxNew,
    Attachments,
    Popup: Dialog,
    Modal,
    InvoicePreviewDialog,
  },
})
export default class ManualForm extends Vue {
  /**
   * Returns the visibility state of the register dialog
   * 
   * @var registerOpen
   * @type boolean
   */
  private registerOpen = false;

  /**
   * Returns whether the form should be saved as template or not
   * 
   * @var saveAsTemplate
   * @type boolean
   */
  private saveAsTemplate = true;

  /**
   * Returns the visibility state of the invoice preview dialog
   * 
   * @var invoicePreviewOpen
   * @type boolean
   */
  private invoicePreviewOpen = false;

  /**
   * Returns the classname for the download button
   * 
   * @var status
   * @type string
   */
  private status = '';

  /**
   * Returns the text for the download button
   * 
   * @var downloadButtonText
   * @type string
   */
  private downloadButtonText = '';

  /**
   * Returns the format
   * 
   * @var format
   * @type string
   */
  get format() {
    return this.$store.state.format;
  }

  /**
   * Sets the format
   * 
   * @var format
   * @type string
   */
  set format(value) {
    this.$store.dispatch("setFormat", value);
    this.$root.$emit('validate', new Function);
  }

  /**
   * Closes the register dialog
   * 
   * @returns void
   */
  registerClosed() {
    this.registerOpen = false;
  }

  /**
   * Closes the invoice preview dialog
   * 
   * @returns void
   */
  invoicePreviewClosed() {
    this.invoicePreviewOpen = false;
  }

  /**
   * Gets called on creation
   * 
   * @return void
   */
  created() {
    this.$root.$off('disable-download-button'); // remove listeners to avoid multiple callings
    this.$root.$on('disable-download-button', function(this:ManualForm) {
      this.status = 'disabled';
      this.downloadButtonText = 'XRechnung generieren';
    }.bind(this));

    this.$root.$off('enable-download-button'); // remove listeners to avoid multiple callings
    this.$root.$on('enable-download-button', function(this:ManualForm) {
      this.status = '';
      this.downloadButtonText = 'XRechnung generieren';
    }.bind(this));

    this.$root.$off('reset-form'); // remove listeners to avoid multiple callings
    this.$root.$on('reset-form', function(this:ManualForm,scrollTo:boolean) {
      this.resetForm(scrollTo);
    }.bind(this));

    this.$root.$emit('disable-download-button');

    this.$root.$off('closeCardsAndFocus');
    this.$root.$on('closeCardsAndFocus', function(this:ManualForm,scrollTo:boolean) {
      window.dispatchEvent(new CustomEvent("closeCards"));
      this.$root.$emit('sellerCardOpenFocus',scrollTo);
    }.bind(this));
  }

  /**
   * Creates a date string in format yyyy-mm-dd
   * 
   * @returns string
   */
  getCurrentDateAsString() {
      const date = new Date(),
            year = date.getFullYear();
        let month = date.getMonth() as  unknown,
            day = date.getDate() as  unknown;

      if((month as number) < 10) month = '0' + month;
      if((day as number) < 10) day = '0' + day;

      return year + '-' + month + '-' + day;
  }

  /**
   * Resets the form values
   * 
   * @returns void
   */
  resetForm(scrollTo:boolean) {
    const currentDate = this.getCurrentDateAsString();

    // rechnungssteller
    this.$store.dispatch("updateSeller", { key : 'BT-29', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-27', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-28', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-35', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-36', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-162', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-38', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-37', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-40', value : '' }); // 'DE'
    this.$store.dispatch("updateSeller", { key : 'BT-31', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-32', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-85', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-84', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-86', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-41', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-42', value : '' });
    this.$store.dispatch("updateSeller", { key : 'BT-43', value : '' });

    // rechnungsempfänger
    this.$store.dispatch("updateBuyer", { key : 'BT-46', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-48', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-56', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-57', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-58', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-44', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-50', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-51', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-163', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-53', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-52', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-55', value : '' }); // 'DE'
    this.$store.dispatch("updateBuyer", { key : 'BT-70', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-75', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-76', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-165', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-78', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-77', value : '' });
    this.$store.dispatch("updateBuyer", { key : 'BT-80', value : '' }); // 'DE'

    // rechnungsdaten
    this.$store.dispatch("updateHeader", { key : 'BT-3', value : 380 });
    this.$store.dispatch("updateHeader", { key : 'FIAT', value : 'EUR' });
    this.$store.dispatch("updateHeader", { key : 'BT-1', value : '' });
    this.$store.dispatch("updateHeader", { key : 'BT-2', value : currentDate });
    this.$store.dispatch("updateHeader", { key : 'BT-13', value : '' });
    this.$store.dispatch("updateHeader", { key : 'BT-72', value : currentDate });
    this.$store.dispatch("updateHeader", { key : 'BT-10', value : '' });
    this.$store.dispatch("updateHeader", { key : 'BT-22', value : '' });
    this.$store.dispatch("updateHeader", { key : 'BT-11', value : '' });
    this.$store.dispatch("updateHeader", { key : 'BT-12', value : '' });
    this.$store.dispatch("updateHeader", { key : 'BT-14', value : '' });
    this.$store.dispatch("updateHeader", { key : 'BT-73', value : '' });
    this.$store.dispatch("updateHeader", { key : 'BT-74', value : '' });
    this.$store.dispatch("updateHeader", { key : 'BT-25', value : '' });
    this.$store.dispatch("updateHeader", { key : 'BT-26', value : '' });
    this.$store.dispatch("updateHeader", { key : 'BT-120', value : '' });

    // zahlungsdaten
    this.$store.dispatch("updateHeader", { key : 'BT-83', value : '' });
    this.$store.dispatch("updateHeader", { key : 'BT-9', value : currentDate });
    this.$store.dispatch("updateHeader", { key : 'BT-20', value : '' });
    this.$store.dispatch("updateHeader", { key : 'BT-81', value : '1' });
    this.$store.dispatch("updateHeader", { key : 'BT-82', value : '' });

    this.$store.state.data.ATTACH = [];
    this.$store.state.data.ALLOWANCE_CHARGE = [];

    this.$store.dispatch("resetPositions");
    this.$store.dispatch("resetSums");

    this.$root.$emit('closeCardsAndFocus',scrollTo);
  }

  /**
   * Validates and opens the visualization window
   * 
   * @returns void
   */
  async visualizeInvoice() {
    await this.$root.$emit('validate', async function(this:ManualForm, result:boolean) {
        if(!result) {
          (this.$refs.dialog as Modal).Open({
            title: "Eingaben fehlerhaft",
            message: "Die von Ihnen eingegeben Belegdaten erfüllen nicht die Anforderungen einer XRechnung.<br>Ihre XRechnung kann erst erzeugen werden, wenn alle Daten korrekt erfasst wurden.<br>Eine Liste aller aktuellen Fehler finden Sie am unteren Bildschirmrand.",
            type: "confirm",
            confirmButtonText : 'OK',
            callback : function(this:ManualForm) {
              this.$root.$emit('validatorOverlayOpen');
            }.bind(this)
          });
          return;
        }

        this.invoicePreviewOpen = true;

    }.bind(this));
  }

  /**
   * Validates, generates and downloads an invoice
   * 
   * @returns void
   */
  async generateInvoice() {

    await this.$root.$emit('validate', async function(this:ManualForm, result:boolean) {
        if(!result) {
          (this.$refs.dialog as Modal).Open({
            title: "Eingaben fehlerhaft",
            message: "Die von Ihnen eingegeben Belegdaten erfüllen nicht die Anforderungen einer XRechnung.<br>Ihre XRechnung kann erst erzeugen werden, wenn alle Daten korrekt erfasst wurden.<br>Eine Liste aller aktuellen Fehler finden Sie am unteren Bildschirmrand.",
            type: "confirm",
            confirmButtonText : 'OK',
            callback : function(this:ManualForm) {
              this.$root.$emit('validatorOverlayOpen');
            }.bind(this)
          });
          return;
        }
        (this.$refs.dialog as Modal).Open({
          title: "Erfolg",
          message: "Ihre XRechnung wurde erfolgreich generiert.<br>Der Download wurde automatisch gestartet.<br>Die Datei sollten Sie als XRechnung.zip in Ihrem Downloadverzeichnis finden.",
          type: "confirm",
          confirmButtonText : 'OK',
          callback : function(this:ManualForm) {
            if (this.$store.state.session === "") this.registerOpen = true;
          }.bind(this)
        });

        const res = await axios.post(
          APIURL + `/xrechnung/${this.$store.state.format}/generate`,
          this.$store.state.data
        );

        //ManualForm.downloadFile(res.data);
        ManualForm.downloadBlob(
          ManualForm.b64toBlob(res.data, "application/zip", 512)
        );

        // Create template from json
        if (this.$store.state.session !== "" && this.saveAsTemplate) {
          await axios.post(APIURL + "/xrechnung/templates/add", {
            sid: this.$store.state.session,
            data: this.$store.state.data
          });
          this.$root.$emit("loadTemplates");
          this.$root.$emit('reset-form');
        } else {
          this.$root.$emit('reset-form');
        }
        
    }.bind(this));
  }

  /**
   * Downloads a zip file
   * 
   * @param string contents The data string
   * @returns void
   */
  private static downloadFile(contents: string) {
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/zip;charset=utf-8," + encodeURIComponent(contents)
    );
    element.setAttribute("download", "XRechnung.zip");

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  /**
   * Converts a base64 to a blob object
   * 
   * @param string b64Data The base64 encoded data
   * @param string contentType The content type
   * @param number sliceSize The slice size
   * @returns Blob
   */
  private static b64toBlob(
    b64Data: string,
    contentType: string,
    sliceSize: number
  ): Blob {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  /**
   * Downloads a blob object
   * 
   * @param Blob blob The blob to download
   * @param string name The download name
   * @returns void
   */
  private static downloadBlob(blob: Blob, name = "XRechnung.zip") {
    if (window.navigator && window.navigator.msSaveOrOpenBlob)
      return window.navigator.msSaveOrOpenBlob(blob);

    // For other browsers:
    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = data;
    link.download = name;

    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(
      new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);
  }

  /**
   * Opens the invoice card
   * 
   * @returns void
   */
  public OpenInvoiceHeader() {
    (this.$refs.invoicedetail as InvoiceDetail).Open();
  }
}
