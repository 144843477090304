













































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Card from "../elements/Card.vue";
import Divider from "@/components/elements/Divider.vue";
import KeyValue from "@/components/elements/KeyValue.vue";

/**
 * Renders the positions part
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: {
    KeyValue,
    Divider,
    Card
  }
})
export default class Positions extends Vue {

  /**
   * Returns the card name
   * 
   * @var cardName
   * @type string
   */
  private cardName = 'Positionen';

  /**
   * Adds a position
   * 
   * @returns void
   */
  addPosition() {
    this.$store.dispatch("addPosition");
  }

  /**
   * Changes the view mode
   * 
   * @param number mode The view mode
   * @returns void
   */
  modeChanged(mode:number) {
    this.$store.state.modes.positions = mode;
    if(mode==0) {
      let i = 0,
          entry;
      const len = this.$store.state.data.ITEM_DETAILS.length;
      for(i; i < len; ++i) {
        entry = this.$store.state.data.ITEM_DETAILS[i];
        entry['BT-128'] = '';
        entry['BT-156'] = '';
        entry['BT-147'] = '';
        entry['BT-134'] = '';
        entry['BT-127'] = '';
        entry['BT-151'] = 'S';
        entry['BT-135'] = '';
        entry['BT-149'] = '';
      }
    }
  }

  /**
   * Removes a position
   * 
   * @param number id The position id
   * @returns void
   */
  removePosition(id: number) {
    console.log("remove pos", id)
    this.$store.dispatch("deletePosition", id);
  }
}
