



























































































import Card from "@/components/elements/Card.vue";
import KeyValue from "@/components/elements/KeyValue.vue";
import { Component, Vue } from "vue-property-decorator";

/**
 * Renders the invoice detail part
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: {
    Card,
    KeyValue
  }
})
export default class InvoiceDetail extends Vue {
  /**
   * Returns the cardName
   * 
   * @var cardName
   * @type string
   */
  private cardName = 'Rechnungsdaten';

  /**
   * Opens the card
   * 
   * @returns void
   */
  public Open() {
    (this.$refs.card as Card).Open();
  }

  /**
   * Changes the view mode
   * 
   * @param number mode The view mode
   * @returns void
   */
  modeChanged(mode:number) {
    this.$store.state.modes.invoiceDetail = mode;
    if(mode==0) {
        // invoice data
        this.$store.state.data.HEADER['BT-22'] = '';
        this.$store.state.data.HEADER['BT-11'] = '';
        this.$store.state.data.HEADER['BT-12'] = '';
        this.$store.state.data.HEADER['BT-14'] = '';
        this.$store.state.data.HEADER['BT-73'] = '';
        this.$store.state.data.HEADER['BT-74'] = '';
        this.$store.state.data.HEADER['BT-25'] = '';
        this.$store.state.data.HEADER['BT-26'] = '';
        this.$store.state.data.HEADER['BT-120'] = '';
    }
  }

  /**
   * Determines the right name based on
   */
  data() {
    return {
      cardNameRes : function(this: InvoiceDetail) {
        if(this.$store.state.mode==0) {
          return 'Belegkopfdaten';
        } else {
          return this.cardName;
        }
      }.bind(this)
    }
  }
}
