
























import { Component, Vue } from "vue-property-decorator";

/**
 * Renders the footer
 * 
 * @class
 * @extends Vue
 */
@Component
export default class Footer extends Vue {}
