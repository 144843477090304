import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import {
  Rechnungsbetrag,
  GesamtBetragPositionen,
  PositionNettopreis,
  PositionGesamtBetrag, 
  Steuerbetrag, 
  Bruttobetrag, 
  Nettobetrag, 
  Steuer,
  calculateAllowances
} from "@/store/AutoCalculationFunctions";
import { APIURL } from "./globals";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    data: {
      SELLER: {},
      BUYER: {},
      HEADER: {},
      ITEM_DETAILS: [],
      ALLOWANCE_CHARGE: [],
      VAT: [],
      ATTACH: []
    },
    format: "cii",
    // 0=easy, 1=advanced
    modes : {
      seller : 0,
      buyer : 0,
      invoiceDetail : 0,
      payment : 0,
      positions: 0
    },
    session:
      localStorage.getItem("SESSION") === null
        ? ""
        : localStorage.getItem("SESSION")
  },
  mutations: {
    UPDATE_SELLER(state, { key, value }) {
      Vue.set(state.data.SELLER, key, value);
    },
    UPDATE_BUYER(state, { key, value }) {
      Vue.set(state.data.BUYER, key, value);
    },
    UPDATE_HEADER(state, { key, value }) {
      Vue.set(state.data.HEADER, key, value);
      //console.log(key);
      switch (key) {
        // Anzahlung changed
        case "BT-113":
        case "BT-112":
          // Update Rechnungsbetrag
          store.commit("UPDATE_HEADER", {
            key: "BT-115",
            value: Rechnungsbetrag(state.data)
          });
          break;
        case "BT-109":
        case "BT-110":
          store.commit("UPDATE_HEADER", {
            key: "BT-112",
            value: Bruttobetrag(state.data)
          });
          break;
      }
    },

    ADD_ITEM_DETAIL(state) {
      state.data.ITEM_DETAILS.push({
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        "BT-126": state.data.ITEM_DETAILS.length,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        "BT-151" : "S",
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        "BT-130" : "H87",
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        "BT-152" : "19",
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        "BT-131":"0.00",
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        "BT-129":0,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        "BT-146":"0.00",
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        "BT-149":"1",
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        "BT-148":0,
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        "BT-147":0
      });
    },
    UPDATE_ITEM_DETAIL(state, { id, key, value }) {
      Vue.set(state.data.ITEM_DETAILS[id], key, value);
      console.log("ITEM", key);

      Steuer(state.data, store);

      switch (key) {
        // Grundpreis / Rabatt changed
        case "BT-147":
        case "BT-148":
          store.commit("UPDATE_ITEM_DETAIL", {
            id: id,
            key: "BT-146",
            value: PositionNettopreis(state.data, id)
          });
          break;
        // Nettopreis changed
        case "BT-146":
        case "BT-129":
          store.commit("UPDATE_ITEM_DETAIL", {
            id: id,
            key: "BT-131",
            value: PositionGesamtBetrag(state.data, id)
          });
          break;
        // Gesamtpreis changed
        case "BT-152":
        case "BT-131":
          store.commit("UPDATE_HEADER", {
            key: "BT-110",
            value: Steuerbetrag(state.data)
          });
          //Steuer(state.data, store);

          // Update GesamtBetragPositionen
          store.commit("UPDATE_HEADER", {
            key: "BT-106",
            value: GesamtBetragPositionen(state.data)
          });
          store.commit("UPDATE_HEADER", {
            key: "BT-109",
            value: Nettobetrag(state.data)
          });
          break;
      }
    },
    RESET_ITEM_DETAILS(state) {
      state.data.ITEM_DETAILS = [];
      store.commit("ADD_ITEM_DETAIL");
      Steuer(state.data, store);
    },
    RESET_ITEM_DETAILS_ALL(state) {
      state.data.ITEM_DETAILS = [];
      Steuer(state.data, store);
    },
    REMOVE_ITEM_DETAIL(state, { id }) {
      Vue.delete(state.data.ITEM_DETAILS, id);
      for(let i = 0; i < state.data.ITEM_DETAILS.length; ++i) {
        Vue.set(state.data.ITEM_DETAILS[i], 'BT-126',i);
      }
      Steuer(state.data, store);
      store.commit("UPDATE_HEADER", {
        key: "BT-115",
        value: Rechnungsbetrag(state.data)
      });
      store.commit("UPDATE_HEADER", {
        key: "BT-110",
        value: Steuerbetrag(state.data)
      });
      //Steuer(state.data, store);

      // Update GesamtBetragPositionen
      store.commit("UPDATE_HEADER", {
        key: "BT-106",
        value: GesamtBetragPositionen(state.data)
      });
      store.commit("UPDATE_HEADER", {
        key: "BT-109",
        value: Nettobetrag(state.data)
      });
    },
    ADD_ALLOWANCE_CHARGE(state) {
      state.data.ALLOWANCE_CHARGE.push({
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        id: state.data.ALLOWANCE_CHARGE.length
      });
    },
    UPDATE_ALLOWANCE_CHARGE(state, { id, key, value }) {
      Vue.set(state.data.ALLOWANCE_CHARGE[id], key, value);

      Steuer(state.data, store);

      switch (key) {
        case "BT-XX":
        case "BT-92":
        case "BT-96":
          store.commit("UPDATE_HEADER", {
            key: "BT-109",
            value: Nettobetrag(state.data)
          });

          store.commit("UPDATE_HEADER", {
            key: "BT-110",
            value: Steuerbetrag(state.data)
          });

          calculateAllowances(state.data);

          //TODO: add to summary

          break;
      }
    },
    REMOVE_ALLOWANCE_CHARGE(state, { id }) {
      Vue.delete(state.data.ALLOWANCE_CHARGE, id);
      for(let i = 0; i < state.data.ALLOWANCE_CHARGE.length; ++i) {
        Vue.set(state.data.ALLOWANCE_CHARGE[i], 'id',i);
      }
      store.commit('RESET_SUMS');
    },
    RESET_SUMS(state) {
      Steuer(state.data, store);
      calculateAllowances(state.data);
      store.commit("UPDATE_HEADER", {
        key: "BT-115",
        value: Rechnungsbetrag(state.data)
      });
      store.commit("UPDATE_HEADER", {
        key: "BT-110",
        value: Steuerbetrag(state.data)
      });
      //Steuer(state.data, store);

      // Update GesamtBetragPositionen
      store.commit("UPDATE_HEADER", {
        key: "BT-106",
        value: GesamtBetragPositionen(state.data)
      });
      store.commit("UPDATE_HEADER", {
        key: "BT-109",
        value: Nettobetrag(state.data)
      });
    },
    UPDATE_ALL_VAT(state, payload) {
      Vue.set(state.data, "VAT", payload);
    },
    ADD_ATTACH(state) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      state.data.ATTACH.push({ "BT-0": state.data.ATTACH.length });
    },
    UPDATE_ATTACH(state, { id, key, value }) {
      Vue.set(state.data.ATTACH[id], key, value);
    },
    REMOVE_ATTACH(state, { id }) {
      Vue.delete(state.data.ATTACH, id);
      for(let i = 0; i < state.data.ATTACH.length; ++i) {
        Vue.set(state.data.ATTACH[i], 'BT-0',i);
      }
    },

    SET_FORMAT(state, value) {
      state.format = value;
    },
    UPDATE_SESSION(state, value) {
      state.session = value;
    }
  },
  actions: {
    updateSeller(context, payload: { key: string; value: string | number }) {
      context.commit("UPDATE_SELLER", payload);
    },
    updateBuyer(context, payload: { key: string; value: string | number }) {
      context.commit("UPDATE_BUYER", payload);
    },
    updateHeader(context, payload: { key: string; value: string | number }) {
      context.commit("UPDATE_HEADER", payload);
    },

    addAllowance(context) {
      context.commit("ADD_ALLOWANCE_CHARGE");
    },
    updateAllowance(context, payload: { key: string; value: string | number }) {
      context.commit("UPDATE_ALLOWANCE_CHARGE", payload);
    },
    deleteAllowance(context, payload: number) {
      context.commit("REMOVE_ALLOWANCE_CHARGE", { id: payload });
    },
    addTax(context) {
      context.commit("ADD_VAT");
    },
    updateTax(context, payload: { key: string; value: string | number }) {
      context.commit("UPDATE_VAT", payload);
    },
    deleteTax(context, payload: number) {
      context.commit("REMOVE_VAT", { id: payload });
    },
    addPosition(context) {
      context.commit("ADD_ITEM_DETAIL");
    },
    updatePosition(
      context,
      payload: { index: number; key: string; value: string | number }
    ) {
      context.commit("UPDATE_ITEM_DETAIL", payload);
    },
    deletePosition(context, payload: number) {
      context.commit("REMOVE_ITEM_DETAIL", { id: payload });
    },
    resetPositions(context) {
      context.commit("RESET_ITEM_DETAILS");
    },
    resetPositionsAll(context) {
      context.commit('RESET_ITEM_DETAILS_ALL');
    },
    resetSums(context) {
      context.commit('RESET_SUMS');
    },
    addAttachment(context) {
      context.commit("ADD_ATTACH");
    },
    updateAttachment(
      context,
      payload: { index: number; key: string; value: string | number }
    ) {
      context.commit("UPDATE_ATTACH", payload);
    },
    deleteAttachment(context, payload: number) {
      context.commit("REMOVE_ATTACH", { id: payload });
    },
    setFormat(context, payload) {
      context.commit("SET_FORMAT", payload);
    },
    login(context, payload) {
      return axios
        .post(APIURL + "/login", {
          user: payload.user,
          password: payload.password
        })
        .then(result => {
          console.log("store login result", result);
          if (result.data != false) {
            context.commit("UPDATE_SESSION", result.data);
            localStorage.setItem("SESSION", result.data);
            return true;
          } else {
            return false;
          }
        });
    },
    async logout(context) {
      await axios
        .post(APIURL + "/logout", {
          sid: context.state.session
        })
        .then(() => {
          context.commit("UPDATE_SESSION", "");
          localStorage.setItem("SESSION", "");
        });
    },
    checkSession(context) {
      axios
        .post(APIURL + "/checkSession", {
          sid: context.state.session
        })
        .then(result => {
          console.log("should check session");
        });
    }
  },
  getters: {}
});
export default store;
