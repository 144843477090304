



















import { Component, Vue, Prop } from "vue-property-decorator";
import { CARDSTORE } from "../../store/globals";
import ToggleButton from "@/components/elements/ToggleButton.vue";

/**
 * Renders a card
 * 
 * Events:
 * - closeCards = window.dispatchEvent(new CustomEvent("closeCards"));
 * 
 * @class
 * @extends Vue
 */
@Component({
  components : {
    ToggleButton
  }
})
export default class Card extends Vue {
  /**
   * Returns the name
   * 
   * @var name
   * @type string
   */
  @Prop() private name!: string;

  /**
   * Returns the label
   * 
   * @var label
   * @type string
   */
  @Prop() private label!: string;

  /**
   * Returns whether the card is opened or not
   * 
   * @var isOpen
   * @type boolean
   */
  @Prop() private isOpen!: boolean;

  /**
   * Returns the mode of the toggle button
   * 
   * @var isOpen
   * @type number
   */
  @Prop() private buttonMode!: number;

  /**
   * Returns whether the card is opened or not 
   * 
   * @var toggleOpen
   * @type boolean
   */
  private toggleOpen!: boolean;

  /**
   * Returns the classname for the validity class. 
   * 
   * Possible value: "invalid"
   * 
   * @var validityClass
   * @type string
   */
  private validityClass = '';

  /**
   * Returns the classname for visibility. 
   * 
   * Possible value: "hidden"
   * 
   * @var visibilityClass
   * @type string
   */
  private visibilityClass = '';

  /**
   * Returns the classname for visibility. 
   * 
   * @param number mode The mode of the toggle button
   * @returns void
   */
  changedButtonMode(mode:number) {
    this.$emit('buttonModeChanged',mode);
  }

  data() {
    return {
      toggleOpen: this.isOpen
    };
  }

  /**
   * Changes the visibility based on the mode
   * 
   * @returns void
   */
  public updateVisibility() {
    this.visibilityClass = '';
    const mode = this.$attrs['data-mode'] ? parseInt(this.$attrs['data-mode']) : 0;
    if(this.$store.state.mode < mode) {
      this.visibilityClass = ' hidden';
    }
  }

  /**
   * Toggle the card open or not
   * 
   * @returns void
   */
  public toggle() {
    if(this.toggleOpen) {
      this.Close();
    } else {
      this.Open();
    }
  }

  /**
   * Get called before mounting
   * 
   * @returns void
   */
  public beforeMount() {
    addEventListener("closeCards", this.Close);
  }

  /**
   * Gets called by creation
   * 
   * @returns void
   */
  created() {
    CARDSTORE[this.name] = this;
  }

  /**
   * Sets the card valid
   * 
   * @returns void
   */
  public setValid() {
    this.validityClass = '';
  }

  /**
   * Sets the card invalid
   * 
   * @returns void
   */
  public setInvalid() {
    this.validityClass = 'invalid';
  }

  /**
   * Closes all cards and open the current
   * 
   * @returns void
   */
  public OnOpen() {
    // Notify all cards to be closed
    //window.dispatchEvent(new CustomEvent("closeCards"));
    // Open the current card
    this.toggle();
    //this.Open();
  }

  /**
   * Opens the card and scroll to the card
   * 
   * @returns void
   */
  public Open(scrollTo = true) {
    this.toggleOpen = true;
    // set scroll position
    setTimeout(function(this:Card) {
      if(!scrollTo) return;
      document.documentElement.scrollTop = (this.$el as HTMLElement).offsetTop;
    }.bind(this),0);
  }

  /**
   * Closes the card
   * 
   * @returns void
   */
  public Close() {
    this.toggleOpen = false;
  }
}
