
















































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { KEYVALUESTORE,CARDSTORE } from "../../store/globals";
import FileInput from "@/components/elements/FileInput.vue";
/**
 * The key value element (label + input element)
 * 
 * Events:
 * KeyValueUpdateFileName${this.storeIndex} (filename:string)
 * 
 * @class
 * @extends Vue
 */
@Component({
  components : {
    FileInput
  }
})
export default class KeyValue extends Vue {
  /**
   * Returns the store key group
   * 
   * @var storeKey
   * @type string
   */
  @Prop() private storeKey!: string;

  /**
   * Returns the index of a store list e.g. positions, attachments
   * 
   * @var storeIndex
   * @type number
   */
  @Prop() private storeIndex!: number;

  /**
   * Returns the store key e.g. all bt-XX values
   * 
   * @var id
   * @type string
   */
  @Prop() private id!: string;

  /**
   * Returns the select id for the numberSelect type
   * 
   * @var id
   * @type string
   */
  @Prop() private selectid!: string;

  /**
   * Returns the label text
   * 
   * @var label
   * @type string
   */
  @Prop() private label!: string;

  /**
   * Returns the name of action which will be dispatched through the store by updating the value property
   * 
   * @var action
   * @type string
   */
  @Prop() private action!: string;

  /**
   * Returns whether the element is required or not
   * 
   * @var required
   * @type boolean
   */
  @Prop() private required!: boolean;

  /**
   * Returns the type of input
   * 
   * @var type
   * @type string
   */
  @Prop() private type!: "select" | "number" | "string" | "date" | "file" | "numberSelect";

  /**
   * Returns whether the compact layout is activated or not
   * 
   * @var compactLayout
   * @type boolean
   */
  @Prop() private compactLayout!: boolean;

  /**
   * Returns whether label will show the [BT-XX] or not
   * 
   * @var labelNoBT
   * @type boolean
   */
  @Prop() private labelNoBT!: boolean;

  /**
   * Returns the unit for example from store key HEADER and id "FIAT"
   * 
   * @var unit
   * @type string
   */
  @Prop() private unit!: string;

  /**
   * Returns the default value 
   * 
   * @var defaultValue
   * @type unknown
   */
  @Prop() private defaultValue!: unknown;

  /**
   * Returns the placeholder for text input types
   * 
   * @var placeholder
   * @type string
   */
  @Prop() private placeholder!: string;

  /**
   * Returns the card name where the element is child of
   * 
   * @var cardName
   * @type string
   */
  @Prop() private cardName!:string;

  /**
   * Returns the classname for visibility. 
   * 
   * Possible value: "hidden"
   * 
   * @var visibilityClass
   * @type string
   */
  private visibilityClass = '';

  /**
   * Returns the classname for set the element invalid 
   * 
   * @var focusClass
   * @type string
   */
  private focusClass = '';

  /**
   * Returns the classname for window dropdown of type numberSelect
   * 
   * @var numberSelectWindowClassName
   * @type string
   */
  private numberSelectWindowClassName = 'hidden';

 /**
   * Returns the readonly property
   * 
   * @var readonly
   * @type boolean
   */
  @Prop() private readonly!: boolean;

  /**
   * Returns the <option> of the select as data
   * 
   * @var numberSelectOptionsData
   * @type object
   */
  private numberSelectOptionsData = {};

  /**
   * Returns the binded function for body omouseclick of type numberSelect
   * 
   * @var numberSelectBodyMouseClickHandler
   * @type Function
   */
  private numberSelectBodyMouseClickHandler : EventListenerOrEventListenerObject;

  /**
   * Opens the select dropdown window of type "numberSelect"
   * 
   * @param e The click event
   * @return void
   */
  public openSelect(e:Event) {
    const elm: HTMLElement = (e.currentTarget as HTMLElement),
          rect = elm.getBoundingClientRect();
    if(elm) {
      const dropdownWindow = elm!.parentNode.parentNode.querySelector('.numberSelect-dropdown') as HTMLElement,
            options = elm!.parentNode.querySelectorAll('option');

      let i = 0,
          option;
      const len = options.length;

      // fill dropdown window with entries
      if(dropdownWindow.innerText.length == 0) {

        for(i ; i < len; ++i) {
          option = options[i] as HTMLOptionElement;
          const a = document.createElement('a');
          a.href = '#';
          a.innerText = option.innerText;
          a.dataset.value = option.value;
          a.onclick = function(this:KeyValue,e:Event) {
            e.preventDefault();
            const caller = e.currentTarget as HTMLElement;
            this.$store.state.data[this.storeKey][this.storeIndex][this.selectid] = caller.dataset.value;
            this.numberSelectWindowClassName = 'hidden';
          }.bind(this);
          dropdownWindow.appendChild(a);

        }

      }

      dropdownWindow.style.top = rect.top + rect.height + document.documentElement.scrollTop + 'px';
      dropdownWindow.style.left = rect.left + 'px';
      this.numberSelectWindowClassName = '';
    }
  }

  /**
   * Changes the visibility based on the mode
   * 
   * @returns void
   */
  public updateVisibility() {
    this.visibilityClass = '';
    const mode = this.$attrs['data-mode'] ? parseInt(this.$attrs['data-mode']) : 0;
    if(this.$store.state.mode == 0
      && mode == 1) {
      this.visibilityClass = ' hidden';
    }
  }

  /**
   * Returns the value
   * 
   * @var value
   * @type string | number
   */
  get value() {
    if (Array.isArray(this.$store.state.data[this.storeKey])) {
      if (
        typeof this.$store.state.data[this.storeKey][this.storeIndex] !==
        "undefined"
      )
        return this.$store.state.data[this.storeKey][this.storeIndex][this.id];
      else return this.defaultValue;
    }
    return this.$store.state.data[this.storeKey][this.id];
  }

  /**
   * Sets the value property
   * 
   * @var value
   * @type string | number
   */
  set value(updatedValue) {
    this.$store.dispatch(this.action, {
      id: this.storeIndex,
      key: this.id,
      value: updatedValue,
    });
  }

  data() {
    return {
      numberSelectSelectText : function(this:KeyValue) {
          const value = this.$store.state.data[this.storeKey][this.storeIndex][this.selectid];
          const data : any = this.numberSelectOptionsData;
          return data[value];
      }.bind(this)
    }
  }

  /**
   * Gets the card instance or null
   * 
   * @returns null | Card
   */
  getCardInstance() {
    if(CARDSTORE[this.cardName] != 'undefined') {
      return CARDSTORE[this.cardName];
    }
    return null;
  }

  /**
   * Sets the element to invalid
   * 
   * @returns void
   */
  setInvalid() {
    this.focusClass = 'invalid';
  }

  /**
   * Sets the element to valid
   * 
   * @returns void
   */
  setValid() {
    this.focusClass = '';
  }

  /**
   * Collects data from the select input (numberSelect type)
   * 
   * @returns object
   */
  collectSelectData() {
    const options = (this.$el as HTMLElement).querySelectorAll('option');

    let i = 0,
        option;
        
    const len = options.length,
          data : Record<string,string> = {};

    for(i ; i < len; ++i) {
      option = options[i] as HTMLOptionElement;
      data[option.value] = option.innerText;
    }

    return data;
  }

  /**
   * Stores the element globally for the validator by creation
   * 
   * @returns void
   */
  created() {
    if(!KEYVALUESTORE[this.id] || KEYVALUESTORE[this.id].length == 2) KEYVALUESTORE[this.id] = [];
    KEYVALUESTORE[this.id].push({
      id : this.id,
      category : this.storeKey,
      instance : this
    });

    this.$root.$off('KeyValueUpdateFileName' + this.storeIndex);
    this.$root.$on('KeyValueUpdateFileName' + this.storeIndex, function(this:KeyValue,filename:string) {
      const fileInput = (this.$refs.fileinput as FileInput);
      if(fileInput) {
        if(filename == 'RESET') {
          fileInput.reset();
        } else {
          fileInput.setFileName(filename);
        }
      }
    }.bind(this));

    setTimeout(function(this:KeyValue) {
      this.numberSelectOptionsData = this.collectSelectData();
    }.bind(this),0);

    document.body.addEventListener('mousedown', this.numberSelectBodyMouseClickHandler = this.numberSelectBodyMouseClick.bind(this),false);

    this.$root.$off('KeyValueUpdateFileName' + this.storeIndex);
    this.$root.$on('KeyValueUpdateFileName' + this.storeIndex, function(this:KeyValue,filename:string) {
      const fileInput = (this.$refs.fileinput as FileInput);
      if(fileInput) {
        if(filename == 'RESET') {
          fileInput.reset();
        } else {
          fileInput.setFileName(filename);
        }
      }
    }.bind(this));
  }

  /**
   * Focuses the input element
   * 
   * @returns void
   */
  focusInput() {
    const input = this.$refs.input as HTMLElement;
    input.setAttribute('tabindex',"-1");
    input.focus();
    input.removeAttribute('tabindex');
  }

  /**
   * When the body got clicked check if its not the window opener or the window itself
   * 
   * @param e The mouse down event
   * @return void
   */
  numberSelectBodyMouseClick(e:Event) {
    const target = e.target as HTMLElement,
          parent = target.parentNode as HTMLElement;
    if(this.type == 'numberSelect' 
        && !parent.classList.contains('select-button')
        && !parent.classList.contains('numberSelect-dropdown')) {
      this.numberSelectWindowClassName = 'hidden';
    }
  }

  /**
   * Removes the mousedown event
   */
  destroyed() {
    document.body.removeEventListener('mousedown',this.numberSelectBodyMouseClickHandler);
  }

  /**
   * Sets the default value before mounting
   * 
   * @returns void
   */
  beforeMount() {
    this.value = this.defaultValue;
  }

  /**
   * Updates the store if the value of the input changed
   * 
   * @returns void
   */
  onValueChanged(event: Event) {
    const reader = new FileReader(),
          input = (event.target as any);

    reader.onload = function (this:KeyValue, e: Event) {
      const res = (e.target as any).result;
      const regex = new RegExp('^data:(.*);base64,');
      const matches = res.match(regex);
      const mime = matches[1];
      const dispatchObject = {
        id: this.storeIndex,
        key: this.id,
        value: JSON.stringify({
          filename : input.value.split(/(\\|\/)/g).pop(),
          data : res.replace('data:' + mime + ';base64,',''),
          mime : mime
        }),
      };

      this.$store.dispatch(this.action, dispatchObject);
      this.$root.$emit('KeyValueFileInputChanged', dispatchObject);

    }.bind(this);
    reader.readAsDataURL(input.files[0]);
  }
}
