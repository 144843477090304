






import { Component, Vue } from "vue-property-decorator";

/**
 * Renders the gradient button
 * 
 * You can add the onclick handler. Example:
 * <GButton @click="event">Label</GButton>
 * 
 * @class
 * @extends Vue
 */
@Component
export default class GButton extends Vue {}
