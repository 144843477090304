



















import { Component, Vue } from "vue-property-decorator";

/**
 * Creates a configurable modal window
 * 
 * How to show a modal:
 * 1. You create a <Modal ref="modal" /> Tag inside the template of your vue file
 * 2. You assign it a ref attribute
 * 3. You call in ts in a method like so:
 *  (this.$refs.modal as Modal).Open({
 *    title: "Vorlage geladen",
 *    message: "Die Vorlage wurde erfolgreich geladen.",
 *    confirmButtonText : 'OK',
 *    type: "confirm", // "yesno"
 *    callback: (result) => {
 *      // result if yes(1) or no(0) was clicked
 *    }
 *  });
 * 
 * @class
 * @extends Vue
 */
@Component
export default class Modal extends Vue {
  /**
   * Returns the open state of the modal
   * 
   * @var isOpen
   * @type boolean
   */
  private isOpen = false;

  /**
   * Returns the title of the modal
   * 
   * @var title
   * @type string
   */
  private title = "";

  /**
   * Returns the message of the modal. It can contain html.
   * 
   * @var message
   * @type string | undefined
   */
  private message: string | undefined = "";

  /**
   * Returns the type of the modal.
   * Can be either "confirm" or "yesno"
   * 
   * @var type
   * @type string
   */
  private type: "confirm" | "yesno" = "confirm";


  /**
   * Returns the text of the button text for the "confirm" type
   * 
   * @var confirmButtonText
   * @type string | undefined
   */
  private confirmButtonText : string | undefined = 'Schließen';


  /**
   * The callback when a button was clicked 
   * 
   * The result parameter contains 1 if the yes button was clicked and 0 when the no button was cicked
   * 
   * @param boolean result If the yes button was clicked or not
   * @returns void
   */
  private callback: (result: boolean) => void = function(result: boolean) {
    console.log(result);
  };

  /**
   * Opens a modal
   * 
   * @param object options The configuration object
   * @returns void
   */
  public Open(options: {
    title: string;
    type: "confirm" | "yesno";
    message?: string;
    confirmButtonText?: string;
    callback?: (result: boolean) => void;
  }) {
    this.title = options.title;
    this.message = options.message;
    if(options.confirmButtonText) {
      this.confirmButtonText = options.confirmButtonText;
    }
    this.type = options.type;
    if (typeof options.callback != "undefined")
      this.callback = options.callback;

    this.isOpen = true;
  }

  /**
   * Gets called when the backdrop was clicked
   * 
   * @param Event event The click event object
   * @returns void
   */
  private onClickBackdrop(event: Event) {
    if ((event.target as HTMLDivElement).classList.contains("gtm-modal-backdrop")) {
      if (this.type === "confirm") this.confirm();
      else this.deny();
    }
  }

  /**
   * Gets called when the confirm button / yes button was clicked
   * 
   * @returns void
   */
  private confirm() {
    if (this.callback) {
      this.callback(true);
    }
    this.close();
  }

  /**
   * Gets called when the no button was clicked
   * 
   * @returns void
   */
  private deny() {
    if (this.callback) {
      this.callback(false);
    }
    this.close();
  }

  /**
   * Closes the modal
   * 
   * @returns void
   */
  private close() {
    this.isOpen = false;
  }
}
