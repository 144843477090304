






































import { Component, Vue } from "vue-property-decorator";
import Card from "../elements/Card.vue";
import KeyValue from "../elements/KeyValue.vue";
import Divider from "@/components/elements/Divider.vue";

/**
 * Renders the attachment part
 * 
 * Events:
 * KeyValueFileInputChanged(data: { id:number; key:string; value:string; })
 * Value is a json string containing keys filename, data, mime
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: {
    Divider,
    KeyValue,
    Card
  }
})
export default class Attachments extends Vue {

  created() {
    this.$root.$off('KeyValueFileInputChanged');
    this.$root.$on('KeyValueFileInputChanged', function(this:Attachments, data : any) {
      const parsed = JSON.parse(data.value);
      const attachEntry = this.$store.state.data.ATTACH[data.id];
      if(attachEntry['BT-122'] == '' || typeof attachEntry['BT-122'] == 'undefined') {
        Vue.set(attachEntry, 'BT-122', parsed.filename);
      }
    }.bind(this));
  }

  /**
   * Adds an attachment
   * 
   * @returns void
   */
  onAddAttachment(): void {
    this.$store.dispatch("addAttachment");
  }

  /**
   * Removes an attachment
   * 
   * @param number id The id of the attachment
   * @returns void
   */
  removeAttachment(id: number) {
    this.$store.dispatch("deleteAttachment", id);
    for(let i = 0; i < this.$store.state.data.ATTACH.length; ++i) {
      const bt125 = this.$store.state.data.ATTACH[i]['BT-125'],
            index = this.$store.state.data.ATTACH[i]['BT-0'];
      if(bt125 != '' && bt125) {
        const data = JSON.parse(bt125);
        this.$root.$emit('KeyValueUpdateFileName' + i, data.filename);
      } else {
        this.$root.$emit('KeyValueUpdateFileName' + i, 'RESET');
      }
    }
  }
}
