










































































import { Component, Vue } from "vue-property-decorator";
import Card from "@/components/elements/Card.vue";
import Divider from "@/components/elements/Divider.vue";
import KeyValue from "@/components/elements/KeyValue.vue";

/**
 * Renders the allowance part
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: {
    KeyValue,
    Card,
    Divider
  }
})
export default class Allowance extends Vue {
  /**
   * Returns the card name
   * 
   * @var cardName
   * @type string
   */
  private cardName = 'Zuschläge und Abschläge';

  /**
   * Adds an allowance entry
   * 
   * @returns void
   */
  addAllowance() {
    this.$store.dispatch("addAllowance");
  }

  /**
   * Removes an allowance entry
   * 
   * @param number id The id of the entry
   * @returns void
   */
  removeAllowance(id: number) {
    this.$store.dispatch("deleteAllowance", id);
  }
}
