







import { Component, Vue, Prop } from "vue-property-decorator";

/**
 * Displays a switch button
 * 
 * @class
 * @extends Vue
 */
@Component
export default class SwitchButton extends Vue {
  /**
   * Returns the text for the left button
   * 
   * @var leftText
   * @type string
   */
  @Prop() private leftText!: string;

  /**
   * Returns the text for the right button
   * 
   * @var rightText
   * @type string
   */
  @Prop() private rightText!: string;

  /**
   * Returns the mode of the switch 0=left,1=right
   * 
   * @var mode
   * @type number
   */
  @Prop() private mode = 0;

  changeState(e:Event,mode:number) {
    e.preventDefault();
    this.$store.state.mode = mode;
  }
}
