




























import axios from "axios";
import { Component, Vue } from "vue-property-decorator";
import { APIURL } from "../../store/globals";
import { KEYVALUESTORE } from "../../store/globals";
import Modal from "./Modal.vue";
import Positions from "../fragments/Positions.vue";

/**
 * Renders the fileinput button
 *
 * You can add the onchange handler. Example:
 * <FileInput @change="event"></FileInput>
 *
 * @class
 * @extends Vue
 */
@Component({
  components: {
    Modal,
    Positions,
  }
})
export default class FileInput extends Vue {
  /**
   * Returns the id of the fileinput
   *
   * @var id
   * @type string
   */
  private id = "";
  /**
   * Returns the filename
   *
   * @var filename
   * @type string
   */
  private filename = "";

  /**
   * Returns the fileinput
   *
   */
  private fileinput = "";

  files: string | Blob;
  /**
   * OnCreated event
   */
  created() {
    this.id =
      "fileinput_" + (Date.now() + Math.ceil(Math.random() * 9999)).toString();
    this.reset();
  }
  /**
   * Reset the input
   *
   * @returns void
   */
  reset() {
    this.filename = "Es wurde keine Datei ausgewählt";
  }

  /**
   * The input files onchange event
   *
   * @returns void
   */
  change(event: Event) {
    const filebutton = document.getElementById("filebutton") as HTMLButtonElement;
    filebutton.disabled = false;
    const input = event.target as any;
    if (input.files.length == 0) return;
    this.setFileName(input.value.split(/(\\|\/)/g).pop());
    this.$emit("change", event);

    this.setFileInput(input.files[0]);
  }

  /**
   * Sets the filename
   *
   * @returns void
   */
  setFileName(name: string) {
    this.filename = name;
  }

  /**
   * Gets the filename
   *
   * @returns void
   */
  getFileName() {
    return this.filename;
  }

  /**
   * Sets the Input
   *
   * @return void
   */
  setFileInput(file: string) {
    this.fileinput = file;
  }

  /**
   * Returns the current status of the dialog
   *
   * 0 = Initial
   * 1 = Success
   * 2 = Error
   * 3 = Loading
   *
   * @var status
   * @type number
   */
  private status = 0;

  /**
   * Returns the close text
   *
   * @var closeText
   * @type string
   */
  private closeText = "";

  /**
   * Returns whether the app needs to be reloaded or not
   *
   * @var needsReload
   * @type boolean
   */
  public needsReload = false;

  /**
   * Sets the status to finished
   *
   * @returns void
   */
  finished() {
    this.status = 1;
    this.closeText = "OK";
  }

  /**
   * Sets the status to error
   *
   * @returns void
   */
  error() {
    this.status = 2;
    this.closeText = "OK";
  }

  async submitFile() {
    const formData = new FormData();
    formData.append("file", this.fileinput);
    const filebutton = document.getElementById("filebutton") as HTMLButtonElement;
    filebutton.disabled = true;
    const spinnercontainer = document.getElementById("spinnercontainer");
    spinnercontainer.style.display = "flex";
    const res = await axios
      .post(APIURL + "/submitFile", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
      .then(async response => {
        if (response.data == 0) {
          (this.$refs.modal as Modal).Open({
            title: "Ihr Kontingent wurde verbraucht",
            message: "Versuchen Sie es Morgen erneut",
            type: "confirm"
          });
          spinnercontainer.style.display = 'none';
          return;
        }
        // get json data, the json will automatically parsed from axios

        const jsondata = response.data;

        const amountdue = jsondata[0].AmountDue;
        const billingaddress = jsondata[0].BillingAddress;
        const billingaddressrecipient = jsondata[0].BillingAddressRecipient;
        const customeraddress = jsondata[0].CustomerAddress;
        const customeraddressrecipient = jsondata[0].CustomerAddressRecipient;
        const customerid = jsondata[0].CustomerId;
        const customername = jsondata[0].CustomerName;
        const duedate = jsondata[0].DueDate;
        const invoicedate = jsondata[0].InvoiceDate;
        const invoiceid = jsondata[0].InvoiceId;
        const invoicetotal = jsondata[0].InvoiceTotal;
        const previousunpaidbalance = jsondata[0].PreviousUnpaidBalance;
        const purchaseorder = jsondata[0].PurchaseOrder;
        const remittanceaddress = jsondata[0].RemittanceAddress;
        const remittanceaddressrecipient = jsondata[0].RemittanceAddressRecipient;
        const serviceaddress = jsondata[0].ServiceAddress;
        const serviceaddressrecipient = jsondata[0].ServiceAddressRecipient;
        const servicestartdate = jsondata[0].ServiceStartDate;
        const serviceendate = jsondata[0].ServiceEndDate;
        const shippingaddress = jsondata[0].ShippingAddress;
        const shippingaddressrecipient = jsondata[0].ShippingAddressRecipient;
        const subtotal = jsondata[0].SubTotal;
        const totaltax = jsondata[0].TotalTax;
        const vendoraddress = jsondata[0].VendorAddress;
        const vendoraddressrecipient = jsondata[0].VendorAddressRecipient;
        const vendorname = jsondata[0].VendorName;
        const totaldiscount = jsondata[0].TotalDiscount;
        const vendortaxid = jsondata[0].VendorTaxId;
        const customertaxid = jsondata[0].CustomerTaxId;
        const paymentterm = jsondata[0].PaymentTerm;
        const paymentdetails = jsondata[0].PaymentDetails;
        const currencycode = jsondata[0].CurrencyCode;

        //Addresse in das richtige format umwandeln
        function formataddress(address: string) {
          if (address !== undefined) {
            const addresscut = address.replace(/- |\| /g, "");
            const addressreplaced = addresscut.replace(/\n|, /g, " ");
            const addresssplit = addressreplaced.split(" ");
            if (addresssplit.length > 4) {
              const adressstreet = addresssplit.slice(0, 3).join(" ");
              const adressplz = addresssplit[3];
              const addressplace = addresssplit[4];
              return [adressstreet, adressplz, addressplace];
            } else if (addresssplit.length > 3) {
              const adressstreet = addresssplit.slice(0, 2).join(" ");
              const adressplz = addresssplit[2];
              const addressplace = addresssplit[3];
              return [adressstreet, adressplz, addressplace];
            } else {
              return addresssplit;
            }
          }
        }

        //Datum in das richtige format umwandeln
        function formatdate(date: string) {
          if (date !== "") {
            const servicedateparts = date.split(".");
            const servicedateyear = servicedateparts[2].length === 2 ? "20" + servicedateparts[2] : servicedateparts[2];
            return date = `${servicedateyear}-${servicedateparts[1]}-${servicedateparts[0]}`;
          }
        }
          //console.log(99999,KEYVALUESTORE);

          //KEYVALUESTORE['BT-29'][0].instance.value = '';                              //Ihre Liferantennummer
          KEYVALUESTORE['BT-27'][0].instance.value = vendorname;                      //Name
          KEYVALUESTORE['BT-28'][0].instance.value = vendoraddressrecipient;          //Handelsname
          KEYVALUESTORE['BT-35'][0].instance.value = formataddress(vendoraddress)[0]; //Adresszeile
          //KEYVALUESTORE['BT-36'][0].instance.value = '';                              //Adresszeile 2
          //KEYVALUESTORE['BT-162'][0].instance.value = '';                             //Adresszeile 3
          KEYVALUESTORE['BT-38'][0].instance.value = formataddress(vendoraddress)[1]; //PLZ vendoraddressplz
          KEYVALUESTORE['BT-37'][0].instance.value = formataddress(vendoraddress)[2]; //Ort
          KEYVALUESTORE['BT-31'][0].instance.value = vendortaxid;                     //USt-id 
          //KEYVALUESTORE['BT-32'][0].instance.value = '';                              //Steuernummer
          //KEYVALUESTORE['BT-85'][0].instance.value = '';                              //Name des Kontoinhabers
          //KEYVALUESTORE['BT-84'][0].instance.value = '';                              //IBAN
          //KEYVALUESTORE['BT-86'][0].instance.value = '';                              //BIC
          //KEYVALUESTORE['BT-41'][0].instance.value = '';                              //Ansprechpartner Name
          //KEYVALUESTORE['BT-42'][0].instance.value = '';                              //Ansprechpartner Telefon
          //KEYVALUESTORE['BT-43'][0].instance.value = '';                              //Ansprechpartner E-Mail
          //Rechnungsempfänger
          KEYVALUESTORE['BT-46'][0].instance.value = customerid;                      //Kundennummer
          //KEYVALUESTORE['BT-48'][0].instance.value = '';                              //USt-id
          //KEYVALUESTORE['BT-56'][0].instance.value = '';                              //Ansprechpartner Name
          //KEYVALUESTORE['BT-57'][0].instance.value = '';                              //Ansprechpartner Telefon
          //KEYVALUESTORE['BT-58'][0].instance.value = '';                              //Ansprechpartner E-Mail
          //Rechnungsadresse
          KEYVALUESTORE['BT-44'][0].instance.value = customeraddressrecipient;        //Name
          KEYVALUESTORE['BT-50'][0].instance.value = formataddress(customeraddress)[0];//Adresszeile
          //KEYVALUESTORE['BT-51'][0].instance.value = '';                              //Adresszeile 2
          //KEYVALUESTORE['BT-163'][0].instance.value = '';                             //Adresszeile 3
          KEYVALUESTORE['BT-53'][0].instance.value = formataddress(customeraddress)[1];//PLZ
          KEYVALUESTORE['BT-52'][0].instance.value = formataddress(customeraddress)[2];//Ort
          //Lieferadresse
          KEYVALUESTORE['BT-70'][0].instance.value = shippingaddressrecipient;        //Name
          KEYVALUESTORE['BT-75'][0].instance.value = formataddress(shippingaddress)[0];//Adresszeile
          //KEYVALUESTORE['BT-76'][0].instance.value = '';                              //Adresszeile 2
          //KEYVALUESTORE['BT-165'][0].instance.value = '';                             //Adresszeile 3
          KEYVALUESTORE['BT-78'][0].instance.value = formataddress(shippingaddress)[1];//PLZ
          KEYVALUESTORE['BT-77'][0].instance.value = formataddress(shippingaddress)[2];//Ort
          //Rechnungsdaten
          KEYVALUESTORE['BT-1'][0].instance.value = invoiceid;                        //Belegnummer
          KEYVALUESTORE['BT-2'][0].instance.value = formatdate(invoicedate);          //Belegdatum
          //KEYVALUESTORE['BT-13'][0].instance.value = '';                              //Bestellnummer
          //KEYVALUESTORE['BT-72'][0].instance.value = '';                              //Lieferdatum
          //KEYVALUESTORE['BT-10'][0].instance.value = '';                              //Leitweg-ID
          //KEYVALUESTORE['BT-22'][0].instance.value = '';                              //Notiz zum Beleg
          //KEYVALUESTORE['BT-11'][0].instance.value = '';                              //Projektnummer
          //KEYVALUESTORE['BT-12'][0].instance.value = '';                              //Vertragsnummer
          //KEYVALUESTORE['BT-14'][0].instance.value = '';                              //Auftragsnummer
          KEYVALUESTORE['BT-73'][0].instance.value = formatdate(servicestartdate);    //Rechnungszeitraum Start
          KEYVALUESTORE['BT-74'][0].instance.value = formatdate(serviceendate);       //Rechnungszeitraum Ende
          //KEYVALUESTORE['BT-25'][0].instance.value = '';                              //Nummer vorausgegangerner Rechnung
          //KEYVALUESTORE['BT-26'][0].instance.value = '';                              //Datum vorausgegangerner Rechnung
          //KEYVALUESTORE['BT-120'][0].instance.value = '';                             //Ausnahme MWST
          //Zahlungsdaten
          //KEYVALUESTORE['BT-83'][0].instance.value = '';                              //Zahlungsreferenz
          KEYVALUESTORE['BT-9'][0].instance.value = formatdate(duedate);              //Zahlbar bis
          //KEYVALUESTORE['BT-20'][0].instance.value = '';                              //Zahlungskonditionen
          //KEYVALUESTORE['BT-82'][0].instance.value = '';                              //Zahlungsmittel
          //Gesamtsummen
          /*
          KEYVALUESTORE['BT-106'][0].instance.value = invoicetotal;                   //Gesamtbetrag der Position
          KEYVALUESTORE['BT-109'][0].instance.value = '';                             //Nettobetrag
          KEYVALUESTORE['BT-110'][0].instance.value = '';                             //Steuerbetrag
          KEYVALUESTORE['BT-112'][0].instance.value = '';                             //Bruttobetrag
          KEYVALUESTORE['BT-108'][0].instance.value = '';                             //Zuschläge
          KEYVALUESTORE['BT-107'][0].instance.value = '';                             //Abschläge
          KEYVALUESTORE['BT-113'][0].instance.value = '';                             //Anzahlung
          KEYVALUESTORE['BT-115'][0].instance.value = '';                             //Rechnungsbetrag
          */

        this.status = 1;

        // removes all positions
        this.$store.dispatch('resetPositionsAll');
        if (jsondata[1] != "") {
        jsondata[1][0].valueArray.forEach(async (element:any,i: number) => {

          let positionamount;
          let positioncurrencycode;
          let positiondate;
          let positiondescription;
          let positioncode;
          let positionquantity;
          let positionunit;
          let positionunitprice;

          try {
            positionamount = jsondata[1][0].valueArray[i].valueObject.Amount.valueCurrency.amount;
          } catch (error) {
            positionamount = 0;
          }
          try {
            positioncurrencycode = jsondata[1][0].valueArray[i].valueObject.Amount.valueCurrency.currencyCode;
          } catch (error) {
            positioncurrencycode = "";
          }
          try {
            positiondate = jsondata[1][0].valueArray[i].valueObject.Date.valueDate;
          } catch (error) {
            positiondate = "";
          }
          try {
            positiondescription = jsondata[1][0].valueArray[i].valueObject.Description.valueString;
          } catch (error) {
            positiondescription = "";
          }
          try {
            positioncode = jsondata[1][0].valueArray[i].valueObject.ProductCode.valueString;
          } catch (error) {
            positioncode = "";
          }
          try {
            positionquantity = jsondata[1][0].valueArray[i].valueObject.Quantity.valueNumber;
          } catch (error) {
            positionquantity = 0;
          }
          try {
            positionunit = jsondata[1][0].valueArray[i].valueObject.Unit.valueString;
          } catch (error) {
            positionunit = "";
          }
          try {
            positionunitprice = jsondata[1][0].valueArray[i].valueObject.UnitPrice.valueCurrency.amount;
          } catch (error) {
            positionunitprice = 0;
          }

          //Artikel-NR von der Bechsreibung trennen
          if (positioncode == "") {
            const positioncodecut = positiondescription.replace(/\n/g, " ");
            const positioncodesplit = positioncodecut.split(" ");
            positioncode = positioncodesplit[0];
            positiondescription = positioncodesplit.slice(1, ).join(" ");
          }

          const dataList = [
            //Stück
              //groß
              { itemId: "", value: "H87" },
              { itemId: "NaN", value: "H87" },
              { itemId: null, value: "H87" },
              { itemId: "Stück", value: "H87" },
              { itemId: "Stck", value: "H87" },
              { itemId: "Stk", value: "H87" },
              { itemId: "St", value: "H87" },
              { itemId: "ST", value: "H87" },
              { itemId: "Pcs", value: "H87" },
              { itemId: "Pc", value: "H87" },
              { itemId: "Units", value: "H87" },
              { itemId: "Einz", value: "H87" },
              { itemId: "E", value: "H87" },
              { itemId: "Qty", value: "H87" },
              //klein
              { itemId: "stück", value: "H87" },
              { itemId: "stck", value: "H87" },
              { itemId: "stk", value: "H87" },
              { itemId: "st", value: "H87" },
              { itemId: "pcs", value: "H87" },
              { itemId: "pc", value: "H87" },
              { itemId: "units", value: "H87" },
              { itemId: "einz", value: "H87" },
              { itemId: "e", value: "H87" },
              { itemId: "qty", value: "H87" },
            //Liter
              //groß
              { itemId: "Liter", value: "LTR" },
              { itemId: "Liter(s)", value: "LTR" },
              { itemId: "Litre(s)", value: "LTR" },
              { itemId: "Ltrs", value: "LTR" },
              { itemId: "Ltr", value: "LTR" },
              { itemId: "Lit", value: "LTR" },
              { itemId: "Lt", value: "LTR" },
              { itemId: "L", value: "LTR" },
              //klein
              { itemId: "liter", value: "LTR" },
              { itemId: "liter(s)", value: "LTR" },
              { itemId: "litre(s)", value: "LTR" },
              { itemId: "ltrs", value: "LTR" },
              { itemId: "ltr", value: "LTR" },
              { itemId: "lit", value: "LTR" },
              { itemId: "lt", value: "LTR" },
              { itemId: "l", value: "LTR" },
            //Kilogramm
              //groß
              { itemId: "Kilogramm", value: "KGM" },
              { itemId: "Kilograms", value: "KGM" },
              { itemId: "Kilogram", value: "KGM" },
              { itemId: "Kilos", value: "KGM" },
              { itemId: "Kilo", value: "KGM" },
              { itemId: "Kgs", value: "KGM" },
              { itemId: "Kg", value: "KGM" },
              { itemId: "Kgm", value: "KGM" },
              //klein
              { itemId: "kilogramm", value: "KGM" },
              { itemId: "kilograms", value: "KGM" },
              { itemId: "kilogram", value: "KGM" },
              { itemId: "kilos", value: "KGM" },
              { itemId: "kilo", value: "KGM" },
              { itemId: "kgs", value: "KGM" },
              { itemId: "kg", value: "KGM" },
              { itemId: "kgm", value: "KGM" },
            //Meter
              //groß
              { itemId: "Meter", value: "MTR" },
              { itemId: "Meters", value: "MTR" },
              { itemId: "Metre", value: "MTR" },
              { itemId: "Mtr", value: "MTR" },
              { itemId: "Met", value: "MTR" },
              { itemId: "Mts", value: "MTR" },
              { itemId: "Mt", value: "MTR" },
              { itemId: "M", value: "MTR" },
              //klein
              { itemId: "meter", value: "MTR" },
              { itemId: "meters", value: "MTR" },
              { itemId: "metre", value: "MTR" },
              { itemId: "mtr", value: "MTR" },
              { itemId: "met", value: "MTR" },
              { itemId: "mts", value: "MTR" },
              { itemId: "mt", value: "MTR" },
              { itemId: "m", value: "MTR" },
            //Festpreis
              //groß
              { itemId: "Festpreis", value: "1I" },
              { itemId: "FP", value: "1I" },
              { itemId: "Fixpreis", value: "1I" },
              { itemId: "Festpr", value: "1I" },
              //klein
              { itemId: "festpreis", value: "1I" },
              { itemId: "fp", value: "1I" },
              { itemId: "fixpreis", value: "1I" },
              { itemId: "festpr", value: "1I" },
            //Stunde
              //groß
              { itemId: "Stunde", value: "HUR" },
              { itemId: "Stunden", value: "HUR" },
              { itemId: "Std", value: "HUR" },
              { itemId: "St", value: "HUR" },
              { itemId: "Hrs", value: "HUR" },
              { itemId: "Hr", value: "HUR" },
              { itemId: "H", value: "HUR" },
              //klein
              { itemId: "stunde", value: "HUR" },
              { itemId: "stunden", value: "HUR" },
              { itemId: "std", value: "HUR" },
              { itemId: "st", value: "HUR" },
              { itemId: "hrs", value: "HUR" },
              { itemId: "hr", value: "HUR" },
              { itemId: "h", value: "HUR" },
            //Tag
              //groß
              { itemId: "Tag", value: "DAY" },
              { itemId: "Tage", value: "DAY" },
              { itemId: "Tage(s)", value: "DAY" },
              { itemId: "Tag(e)", value: "DAY" },
              { itemId: "Tagest", value: "DAY" },
              { itemId: "Tg", value: "DAY" },
              { itemId: "Days", value: "DAY" },
              //klein
              { itemId: "tag", value: "DAY" },
              { itemId: "tage", value: "DAY" },
              { itemId: "tage(s)", value: "DAY" },
              { itemId: "tag(e)", value: "DAY" },
              { itemId: "tagest", value: "DAY" },
              { itemId: "tg", value: "DAY" },
              { itemId: "days", value: "DAY" },
            //Tonne
              //groß
              { itemId: "Tonne (metrische Tonne)", value: "TNE" },
              { itemId: "Tonne(n)", value: "TNE" },
              { itemId: "Tonne", value: "TNE" },
              { itemId: "Tonnen", value: "TNE" },
              { itemId: "Tons", value: "TNE" },
              { itemId: "Ton", value: "TNE" },
              { itemId: "To", value: "TNE" },
              { itemId: "Tn", value: "TNE" },
              { itemId: "T", value: "TNE" },
              //klein
              { itemId: "tonne (metrische Tonne)", value: "TNE" },
              { itemId: "tonne(n)", value: "TNE" },
              { itemId: "tonne", value: "TNE" },
              { itemId: "tonnen", value: "TNE" },
              { itemId: "tons", value: "TNE" },
              { itemId: "ton", value: "TNE" },
              { itemId: "to", value: "TNE" },
              { itemId: "tn", value: "TNE" },
              { itemId: "t", value: "TNE" },
            //Quadratmeter
              //groß
              { itemId: "Quadratmeter", value: "MTK" },
              { itemId: "qm", value: "MTK" },
              { itemId: "m²", value: "MTK" },
              { itemId: "Quadratm.", value: "MTK" },
              { itemId: "m2", value: "MTK" },
              { itemId: "sqm", value: "MTK" },
              { itemId: "m^2", value: "MTK" },
              //klein
              { itemId: "quadratmeter", value: "MTK" },
              { itemId: "quadratm.", value: "MTK" },
            //Paar
              //groß
              { itemId: "Paar", value: "PR" },
              { itemId: "Paare", value: "PR" },
              { itemId: "Pair", value: "PR" },
              { itemId: "Prs", value: "PR" },
              { itemId: "Pr", value: "PR" },
              { itemId: "P", value: "PR" },
              //klein
              { itemId: "paar", value: "PR" },
              { itemId: "paare", value: "PR" },
              { itemId: "pair", value: "PR" },
              { itemId: "prs", value: "PR" },
              { itemId: "pr", value: "PR" },
              { itemId: "p", value: "PR" },
            //Woche
              //groß
              { itemId: "Woche", value: "WEE" },
              { itemId: "Wochen", value: "WEE" },
              { itemId: "Wo", value: "WEE" },
              { itemId: "Wks", value: "WEE" },
              { itemId: "Wk", value: "WEE" },
              { itemId: "W", value: "WEE" },
              //klein
              { itemId: "woche", value: "WEE" },
              { itemId: "wochen", value: "WEE" },
              { itemId: "wo", value: "WEE" },
              { itemId: "wks", value: "WEE" },
              { itemId: "wk", value: "WEE" },
              { itemId: "w", value: "WEE" },
            //Kilometer
              //groß
              { itemId: "Kilometer", value: "KMT" },
              { itemId: "Kilometer(s)", value: "KMT" },
              { itemId: "Km", value: "KMT" },
              { itemId: "KM", value: "KMT" },
              { itemId: "Kms", value: "KMT" },
              //klein
              { itemId: "kilometer", value: "KMT" },
              { itemId: "kilometer(s)", value: "KMT" },
              { itemId: "km", value: "KMT" },
              { itemId: "kms", value: "KMT" }
          ];

        for (const item of dataList) {
          if (positionunit == item.itemId) {
            positionunit = item.value;
          break;
          }
        }

          await this.$store.dispatch("addPosition");
          const entry = this.$store.state.data.ITEM_DETAILS[i];
          //Simple view
          entry['BT-155'] = positioncode;             //Artikel-NR (eigene)
          entry['BT-153'] = positiondescription;      //Bezeichnung
          entry['BT-129'] = positionquantity;         //Menge
          entry['BT-148'] = positionunitprice;        //Preis
          //entry['BT-152'] = '19';                     //MwSt.
          entry['BT-130'] = positionunit              //Einheit
          //Expert view
          //entry['BT-128'] = '';                       //Objekt Kennung
          //entry['BT-156'] = '';                       //Artikel-Nr (Kunde)
          //entry['BT-147'] = '';                       //Rabatt(Betrag)
          entry['BT-134'] = positiondate;             //Start Abrechnungszeitraum
          //entry['BT-127'] = '';                       //Notiz zur Position
          //entry['BT-151'] = 'S';                      //Steuerkatogorie
          //entry['BT-135'] = '';                       //Ende Abrechnungszeitraum
          //entry['BT-149'] = '';                       //Preis Basis Menge
          //entry['BT-146'] = '';                       //Nettopreis
          entry['BT-131'] = positionamount;           //Gesamtbetrag

        });
        // force rerender to fill out the first position
        await this.$store.dispatch("addPosition");
        setTimeout(() => this.$store.dispatch('deletePosition',this.$store.state.data.ITEM_DETAILS.length-1),100);
        } 
        const uses = jsondata[3].uses;

        this.reset();

        if (this.status == 1) {
          (this.$refs.modal as Modal).Open({
            title: "Import erfolgreich",
            message: "Ihnen verbleiben heute noch " + uses + " Vorgänge",
            type: "confirm"
          });
          spinnercontainer.style.display = 'none';
        return;
        }

    });
  }
}
