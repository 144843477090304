






















import { Component, Vue, Prop } from "vue-property-decorator";
import Dialog from "@/components/elements/Dialog.vue";
import { APIURL } from "../../store/globals";
import axios from "axios";

/**
 * Renders the invoice preview dialog
 * 
 * @class
 * @extends Vue
 */
@Component({
    components: { Dialog }
})
export default class InvoicePreviewDialog extends Vue {
  /**
   * Returns the visiblity state of the dialog
   * 
   * @var isOpen
   * @type boolean
   */
  @Prop() private isOpen!: boolean;

  /**
   * Returns classname of the content div
   * 
   * @var contentClassName
   * @type string
   */
  private contentClassName = '';

  /**
   * Closes the dialog
   * 
   * @returns void
   */
  private close() {
    this.$emit("close");
  }

  /**
   * Opens the dialog and loads the preview
   * 
   * @returns void
   */
  async open() {
    this.contentClassName = 'loading';
    const html = false,
        res = await axios.post(
        APIURL + `/xrechnung/${this.$store.state.format}/visualize`,{
          sid : localStorage.SESSION,
          data : JSON.stringify(this.$store.state.data),
          html : html
        }),
      data = res.data,
      iframe = (this.$refs.iframe as HTMLIFrameElement),
      message = (this.$refs.message as HTMLElement),
      win = iframe.contentWindow!;

      this.contentClassName = '';

    if(html) {
      win.document.open();
      win.document.write(data);
      win.document.close();
    } else {
      switch(parseInt(data.status)) {
        case 200:
          if(data.body.error != '' && data.body.error != null) {
            this.contentClassName = 'message';
            message.innerText = data.body.error;
          } else {
            iframe.src = 'data:application/pdf;base64,' + data.body.pdf;
          }
          break;
        default:
          this.contentClassName = 'message';
          message.innerText = data.body;
          break;
      }
    }
      
  }

  /**
   * Gets called on creation
   * 
   * @returns void
   */
  created() {
    this.$watch('isOpen', function(newVal, oldVal) {
      if(newVal) {
        this.open();
      }
    });
  }

}
