







import { Component, Vue, Prop } from "vue-property-decorator";

/**
 * Displays a toggle button
 * 
 * @class
 * @extends Vue
 */
@Component({
    components: {}
})
export default class ToggleButton extends Vue {
  /**
   * Returns the text for the left button
   * 
   * @var leftText
   * @type string
   */
  @Prop() private textOn!: string;

  /**
   * Returns the text for the right button
   * 
   * @var rightText
   * @type string
   */
  @Prop() private textOff!: string;

  /**
   * Returns the state of the toggle state
   * 
   * @var state
   * @type number
   */
  @Prop() private state! : number;

  /**
   * Returns the mode of the toggle state
   * 
   * @var mode
   * @type number
   */
  private mode = 0;

  data() {
      return {
          mode : this.state
      }
  }

  toggleState(e:Event,newmode:number) {
    e.preventDefault();
    this.mode = newmode;
    this.$emit('changed', newmode);
  }
}
