












import { Component, Vue } from "vue-property-decorator";

/**
 * The basic frame for the application
 * 
 * @extends Vue
 * @class
 */
@Component
export default class PageFrame extends Vue {}
