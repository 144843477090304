import Vue from "vue";
import store from "./index";

/**
 * The type of store data
 */
type StateData = {
  SELLER: Record<string, number>;
  BUYER: Record<string, number>;
  HEADER: Record<string, number>;
  ITEM_DETAILS: Record<string, number>[];
  ALLOWANCE_CHARGE: Record<string, number>[];
  VAT: Record<string, number>[];
  ATTACH: Record<string, number>[];
};

/**
 * The type for an allowance
 */
type Allowance = {
  id: number; 
  "BT-XX"?: string; 
  "BT-116": number; 
  "BT-117": number; 
  "BT-119": number; 
  "BT-118": string; 
};

/**
 * Formats a number to 2 decimal digits
 * 
 * @param number number The number to format
 * @returns string
 */
function FormatNumber(number:number) {
  number = Math.round((number + Number.EPSILON) * 100) / 100;
  return number.toFixed(2);
}

/**
 * Get the net price of a position
 * 
 * @param StateData data The store data
 * @param number id The id of the position
 * @returns string
 */
export function PositionNettopreis(data: StateData, id: number) {
  if (typeof data.ITEM_DETAILS[id]["BT-147"] === "undefined")
    Vue.set(data.ITEM_DETAILS[id], "BT-147", 0);

  if (typeof data.ITEM_DETAILS[id]["BT-148"] === "undefined")
    Vue.set(data.ITEM_DETAILS[id], "BT-148", 0);

  console.log(data.ITEM_DETAILS[id]["BT-148"], data.ITEM_DETAILS[id]["BT-147"]);
  return FormatNumber(
    Number(data.ITEM_DETAILS[id]["BT-148"]) -
    Number(data.ITEM_DETAILS[id]["BT-147"])
  );
}

/**
 * Get the summary price of a position
 * 
 * @param StateData data The store data
 * @param number id The id of the position
 * @returns string
 */
export function PositionGesamtBetrag(data: StateData, id: number) {
  if (typeof data.ITEM_DETAILS[id]["BT-129"] === "undefined")
    Vue.set(data.ITEM_DETAILS[id], "BT-129", 0);

  if (typeof data.ITEM_DETAILS[id]["BT-146"] === "undefined")
    Vue.set(data.ITEM_DETAILS[id], "BT-146", 0);

  if (typeof data.ITEM_DETAILS[id]["BT-149"] === "undefined")
    Vue.set(data.ITEM_DETAILS[id], "BT-149", 1);

  console.log(data.ITEM_DETAILS[id]["BT-129"], data.ITEM_DETAILS[id]["BT-146"]);
  return FormatNumber((Number(data.ITEM_DETAILS[id]["BT-129"]) * Number(data.ITEM_DETAILS[id]["BT-146"])) / Number(data.ITEM_DETAILS[id]["BT-149"]));
}

/**
 * Gets the summary price of all positions
 * 
 * @param StateData data The store data 
 * @returns string
 */
export function GesamtBetragPositionen(data: StateData) {
  let result = 0;

  if (data.ITEM_DETAILS.length <= 0) return result;

  for (let i = 0; i < data.ITEM_DETAILS.length; i++) {
    if (typeof data.ITEM_DETAILS[i]["BT-131"] === "undefined") continue;

    result += Number(data.ITEM_DETAILS[i]["BT-131"]);
  }

  return FormatNumber(result);
}

/**
 * Gets the summary net price
 * 
 * @param StateData data The store data 
 * @returns string
 */
export function Nettobetrag(data: StateData) {
  let result = 0;

  if (typeof data.HEADER["BT-106"] !== "undefined")
    result = Number(data.HEADER["BT-106"]);

  if (data.ALLOWANCE_CHARGE.length <= 0) return result;

  for (let i = 0; i < data.ALLOWANCE_CHARGE.length; i++) {
    if (typeof data.ALLOWANCE_CHARGE[i]["BT-92"] === "undefined") continue;

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (data.ALLOWANCE_CHARGE[i]["BT-XX"] === "CHARGE") {
      result += Number(data.ALLOWANCE_CHARGE[i]["BT-92"]);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (data.ALLOWANCE_CHARGE[i]["BT-XX"] === "ALLOWANCE") {
        result -= Number(data.ALLOWANCE_CHARGE[i]["BT-92"]);
      }
    }
  }

  return FormatNumber(result);
}

/**
 * Gets the tax price
 * 
 * @param StateData data The store data 
 * @returns string
 */
export function Steuerbetrag(data: StateData) {
  let result = 0;

  if (data.ITEM_DETAILS.length <= 0) return result;

  for (let i = 0; i < data.ITEM_DETAILS.length; i++) {
    if (typeof data.ITEM_DETAILS[i]["BT-152"] === "undefined")
      Vue.set(data.ITEM_DETAILS[i], "BT-152", 0);

    if (typeof data.ITEM_DETAILS[i]["BT-131"] === "undefined")
      Vue.set(data.ITEM_DETAILS[i], "BT-131", 0);

    result +=
      (Number(data.ITEM_DETAILS[i]["BT-152"]) / 100) *
      Number(data.ITEM_DETAILS[i]["BT-131"]);
  }

  if (data.ALLOWANCE_CHARGE.length <= 0) return result;

  for (let i = 0; i < data.ALLOWANCE_CHARGE.length; i++) {
    if (typeof data.ALLOWANCE_CHARGE[i]["BT-92"] === "undefined")
      Vue.set(data.ALLOWANCE_CHARGE[i], "BT-92", 0);

    if (typeof data.ALLOWANCE_CHARGE[i]["BT-96"] === "undefined")
      Vue.set(data.ALLOWANCE_CHARGE[i], "BT-96", 0);

    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    if (data.ALLOWANCE_CHARGE[i]["BT-XX"] === "CHARGE") {
      result +=
        (Number(data.ALLOWANCE_CHARGE[i]["BT-96"]) / 100) *
        Number(data.ALLOWANCE_CHARGE[i]["BT-92"]);
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      if (data.ALLOWANCE_CHARGE[i]["BT-XX"] === "ALLOWANCE") {
        result -=
          (Number(data.ALLOWANCE_CHARGE[i]["BT-96"]) / 100) *
          Number(data.ALLOWANCE_CHARGE[i]["BT-92"]);
      }
    }
  }

  return FormatNumber(result);
}

/**
 * Gets the gross price
 * 
 * @param StateData data The store data 
 * @returns string
 */
export function Bruttobetrag(data: StateData) {
  if (typeof data.HEADER["BT-109"] === "undefined")
    Vue.set(data.HEADER, "BT-109", 0);

  if (typeof data.HEADER["BT-110"] === "undefined")
    Vue.set(data.HEADER, "BT-110", 0);

  return FormatNumber(Number(data.HEADER["BT-109"]) + Number(data.HEADER["BT-110"]));
}

/**
 * Gets the invoice price
 * 
 * @param StateData data The store data 
 * @returns string
 */
export function Rechnungsbetrag(data: StateData) {
  if (typeof data.HEADER["BT-112"] === "undefined")
    Vue.set(data.HEADER, "BT-112", 0);

  if (typeof data.HEADER["BT-113"] === "undefined")
    Vue.set(data.HEADER, "BT-113", 0);

  const result = data.HEADER["BT-112"] - data.HEADER["BT-113"];

  return FormatNumber(result);
}

/**
 * Calculates the tax and updates the all vat fields
 * 
 * @param StateData data The store data 
 * @param Store datastore The datastore
 * @returns void
 */
export function Steuer(data: StateData, datastore: typeof store) {
  const tempres = [];

  for (let i = 0; i < data.ITEM_DETAILS.length; i++) {
    const steuergruppe = Number(data.ITEM_DETAILS[i]["BT-152"]);
    if (isNaN(steuergruppe)) continue;

    if (isNaN(data.ITEM_DETAILS[i]["BT-131"])) continue;

    //console.log(tempres, steuergruppe, tempres[steuergruppe]);

    let found = false;

    for (let j = 0; j < tempres.length; j++) {
      if (tempres[j].id === steuergruppe && (tempres[j]["BT-118"] as unknown) === data.ITEM_DETAILS[i]["BT-151"]) {
        found = true;
        tempres[j]["BT-116"] = (Number(tempres[j]["BT-116"]) + Number(data.ITEM_DETAILS[i]["BT-131"]));
        tempres[j]["BT-117"] = ((Number(tempres[j]["BT-116"]) * (Number(steuergruppe) / 100)));
      }
    }

    if (!found) {
      console.log(9999,data.ITEM_DETAILS[i]);
      tempres.push({
        id: steuergruppe,
        "BT-116": (Number(data.ITEM_DETAILS[i]["BT-131"])),
        "BT-117": (Number(data.ITEM_DETAILS[i]["BT-131"]) * (Number(steuergruppe) / 100)),
        "BT-119": steuergruppe,
        "BT-118": data.ITEM_DETAILS[i]["BT-151"]
      });
    }
  }

  for (let i = 0; i < data.ALLOWANCE_CHARGE.length; i++) {
    const steuergruppe = Number(data.ALLOWANCE_CHARGE[i]["BT-96"]);
    if (isNaN(steuergruppe)) continue;

    if (isNaN(data.ALLOWANCE_CHARGE[i]["BT-92"])) continue;

    //console.log(tempres, steuergruppe, tempres[steuergruppe]);

    let found = false;

    for (let j = 0; j < tempres.length; j++) {
      if (tempres[j].id === steuergruppe) {
        found = true;

        let bt116 = Number(data.ALLOWANCE_CHARGE[i]["BT-92"]);
        if(data.ALLOWANCE_CHARGE[i]["BT-XX"].toString() == 'ALLOWANCE') {
          bt116 = -bt116;
        }

        tempres[j]["BT-116"] = (Number(tempres[j]["BT-116"]) + bt116);
        tempres[j]["BT-117"] = (Number(tempres[j]["BT-116"]) * (Number(steuergruppe) / 100));
      }
    }

    if (!found) {

      let bt116 = Number(data.ALLOWANCE_CHARGE[i]["BT-92"]);
      if(data.ALLOWANCE_CHARGE[i]["BT-XX"].toString() == 'ALLOWANCE') {
        bt116 = -bt116;
      }

      const allowanceObj : Allowance = {
        id: steuergruppe,
        "BT-116": (bt116),
        "BT-117": (bt116 * (Number(steuergruppe) / 100)),
        "BT-119": steuergruppe,
        "BT-118": "S"
      };

      tempres.push(allowanceObj);
    }
  }

  for (let j = 0; j < tempres.length; j++) {
    tempres[j]["BT-116"] = parseFloat(FormatNumber(tempres[j]["BT-116"]));
    tempres[j]["BT-117"] = parseFloat(FormatNumber(tempres[j]["BT-117"]));
  }

  console.log(tempres);
  //Vue.set(data, "VAT", tempres);
  datastore.commit("UPDATE_ALL_VAT", tempres);
}

/**
 * Calculates the allowances
 * 
 * @param StateData data The store data
 * @returns void
 */
export function calculateAllowances(data: StateData) {
  let positiveAllowance = 0;
  let negativeAllowance = 0;

  for (let i = 0; i < data.ALLOWANCE_CHARGE.length; i++) {
    if ((data.ALLOWANCE_CHARGE[i]["BT-XX"] as unknown as string) === "CHARGE") {
      positiveAllowance += Number(data.ALLOWANCE_CHARGE[i]["BT-92"]);
    } else {
      negativeAllowance += Number(data.ALLOWANCE_CHARGE[i]["BT-92"]);
    }
  }

  Vue.set(data.HEADER, "BT-108", FormatNumber(positiveAllowance));
  Vue.set(data.HEADER, "BT-107", FormatNumber(negativeAllowance));
}
