import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Home from "../components/pages/Home.vue";
import axios from "axios";
import store from "../store";
import { APIURL } from "../store/globals";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Rechnung erfassen",
    meta: {
      title: 'XRechnung kostenfrei: schnell & einfach auf XRechnung.new',
      frametitle: "XRECHNUNG.NEW",
      show: "both"
    },
    component: Home
  },
  /*{
    path: "/template",
    name: "Vorlage verwenden",
    meta: {
      title: "Premium Webservice für XRechnungen"
    },
    component: () => import("../components/pages/Template.vue")
  },
  {
    path: "/print",
    name: "Per Druckbefehl erzeugen",
    component: () => import("../components/pages/Print.vue")
  },
  {
    path: "/api",
    name: "API-Automatisierung",
    component: () => import("../components/pages/Api.vue")
  },
   */
  {
    path: "/pricing",
    name: "Preise",
    meta: {
      title : 'XRechnung kostenfrei: schnell & einfach auf XRechnung.new - Unsere Services',
      frametitle: "Unsere Services",
      show: "out"
    },
    component: () => import("../components/pages/Pricing.vue")
  },
  {
    path: "/profile",
    name: "Profil",
    meta: {
      title : 'XRechnung kostenfrei: schnell & einfach auf XRechnung.new - Profil',
      frametitle: "XRECHNUNG.NEW - Profil",
      show: "in"
    },
    component: () => import("../components/pages/Profile.vue")
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach(async function(this: VueRouter, to, from, next) {
  
  const session = store.state.session;
  if(session) {

    const res = await axios
    .post(APIURL + "/checkSession", {
      sid: session
    }),
    data = res.data;
    if(data) {
      document.title = to.meta.title;
      next();
    } else {
      await store.dispatch("logout");
      router.app.$root.$emit('invalidSession');
    }
    
  } else {
    document.title = to.meta.title;
    next();
  }
});

export default router;
