








import { Component, Vue, Prop } from "vue-property-decorator";

/**
 * Handles the dialog interactions
 * 
 * Look at the compoennts/dialog folder for implementation examples
 * 
 * @class
 * @extends Vue
 */
@Component
export default class Dialog extends Vue {
  /**
   * Returns whether the dialog is shown or not
   * 
   * @var isOpen
   * @type boolean
   */
  @Prop() private isOpen!: boolean;

  /**
   * Returns the custom className for the dialog
   * 
   * @var className
   * @type string
   */
  @Prop() private className!:string;

  /**
   * Returns whether the curtain/backdrop can be close the dialog or not
   * 
   * @var cantCloseThroughCurtain
   * @type boolean
   */
  @Prop() private cantCloseThroughCurtain!:boolean;

  /**
   * Handles the onclose event
   * 
   * @param MouseEvent event The mouse event object
   * @returns void
   */
  private onClose(event: MouseEvent) {
    if(this.cantCloseThroughCurtain) return;
    if ((event.target as HTMLElement).classList.contains("gtm-popup-curtain")) {
      this.$emit("close");
    }
  }
}
