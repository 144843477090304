
































































import { Component, Vue } from "vue-property-decorator";
import Card from "@/components/elements/Card.vue";
import KeyValue from "@/components/elements/KeyValue.vue";
import Divider from "@/components/elements/Divider.vue";

/**
 * Renders the tax part
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: {
    Divider,
    KeyValue,
    Card
  }
})
export default class Tax extends Vue {
  /**
   * Returns the card name
   * 
   * @var cardName
   * @type string
   */
  private cardName = 'Aufschlüsselung Steuern';

  /**
   * Adds a tax position
   * 
   * @returns void
   */
  addTax() {
    this.$store.dispatch("addTax");
  }

  /**
   * Removes a tax position
   * 
   * @param number id The tax id
   * @returns void
   */
  removeTax(id: number) {
    this.$store.dispatch("deleteTax", id);
  }
}
