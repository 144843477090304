
















import { Component, Vue, Prop } from "vue-property-decorator";
import Dialog from "@/components/elements/Dialog.vue";
import Modal from "@/components/elements/Modal.vue";
import { APIURL } from "../../store/globals";

/**
 * Renders the login dialog
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: { Modal, Dialog }
})
export default class LoginDialog extends Vue {
  /**
   * Returns the visiblity state of the dialog
   * 
   * @var isOpen
   * @type boolean
   */
  @Prop() private isOpen!: boolean;

  /**
   * Returns the email value
   * 
   * @var emailModel
   * @type string
   */
  private emailModel = "";

  /**
   * Returns the password value
   * 
   * @var passwordModel
   * @type string
   */
  private passwordModel = "";

  /**
   * Returns the password forgot url
   * 
   * @var pwForgotUrl
   * @type string
   */
  private pwForgotUrl = "";

  /**
   * Closes the dialog
   * 
   * @returns void
   */
  private close() {
    this.$emit("close");
  }

  /**
   * Gets called when created
   * 
   * @returns void
   */
  created() {
    this.pwForgotUrl = APIURL + '/to-password-forgot';
  }

  /**
   * Sends a login to the server
   * 
   * @returns void
   */
  async sendLogin() {
    this.$store
      .dispatch("login", {
        user: this.emailModel,
        password: this.passwordModel
      })
      .then(result => {
        if (result) {
          (this.$refs.modalDummy as Modal).Open({
            title: "Anmeldung erfolgreich",
            type: "confirm",
            callback: () => {
              this.$root.$emit('loadTemplates');
              this.emailModel = "";
              this.passwordModel = "";

              if (this.$route.path !== '/') {
                this.$router.push('/');
              }

              this.close();
            }
          });
        } else {
          (this.$refs.modalDummy as Modal).Open({
            title: "Anmeldung fehlgeschlagen",
            message: "Email-Adresse oder Passwort falsch",
            type: "confirm"
          });
          this.passwordModel = "";
        }
      });
  }
}
