<template>
  <Card :name="cardName">
    <KeyValue
      id="BT-83"
      :cardName="cardName"
      store-key="HEADER"
      label="Zahlungsreferenz"
      type="string"
      action="updateHeader"
      placeholder=""
    />
    <KeyValue
      id="BT-9"
      :cardName="cardName"
      store-key="HEADER"
      label="Zahlbar bis"
      type="date"
      action="updateHeader"
      v-show="$store.state.modes.invoiceDetail==1"
    />
    <KeyValue
      id="BT-20"
      :cardName="cardName"
      store-key="HEADER"
      label="Zahlungskonditionen"
      type="string"
      action="updateHeader"
      placeholder=""
      v-show="$store.state.modes.invoiceDetail==1"
    />
    <KeyValue
      id="BT-81"
      :cardName="cardName"
      store-key="HEADER"
      label="Zahlungsmittel (Code)"
      type="select"
      action="updateHeader"
      required
      defaultValue="1"
    >
      <option value="1">Nicht definiert (1)</option>
      <option value="30">Überweisung (30)</option>
      <option value="58">SEPA Überweisung (58)</option>
      <option value="54">Kreditkarte (54)</option>
      <option value="55">Debitkarte (55)</option>
      <option value="68">Online Zahlungsdienst (68)</option>
      <option value="10">In Bar (10)</option>
    </KeyValue>
    <KeyValue
      id="BT-82"
      :cardName="cardName"
      store-key="HEADER"
      label="Zahlungsmittel (Freitext)"
      type="string"
      action="updateHeader"
    />
  </Card>
</template>

<script>
import { Component, Vue } from "vue-property-decorator";
import Card from "@/components/elements/Card";
import KeyValue from "@/components/elements/KeyValue";

/**
 * Renders the payment part
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: { KeyValue, Card },
})
export default class Payment extends Vue {
  /**
   * Returns the name of the card
   * 
   * @var cardName
   * @type string
   */
  cardName = 'Zahlungsdaten';
}
</script>

<style scoped></style>
