










import { Component, Vue, Prop } from "vue-property-decorator";

/**
 * Renders the cookie law banner
 * 
 * @class
 * @extends Vue
 */
@Component({})
export default class CookieLaw extends Vue {
  /**
   * Returns the classname for the cookie-banner
   * 
   * @var className
   * @type string
   */
  private className = '';

  /**
   * Adds analytics to the document
   * 
   * @returns void
   */
  addAnalytics() {
    const script = document.createElement('script');
    script.innerHTML = `
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-WM3TTWN');
    `;
    document.head.appendChild(script);
  }

  /**
   * Gets called when created
   * 
   * @returns void
   */
  created() {
    if(localStorage.acceptedcookies == 'true') {
      this.className = 'hide';
      this.addAnalytics();
    }
  }

  /**
   * Hides the banner and add analytics
   * 
   * @returns void
   */
  accept() {
    this.className = 'hide';

    this.addAnalytics();

    localStorage.acceptedcookies = true;
  }

}
