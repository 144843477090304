









import { Component, Vue } from "vue-property-decorator";
import Header from "@/components/elements/Header.vue";
import Footer from "@/components/elements/Footer.vue";
import Modal from "@/components/elements/Modal.vue";
import CookieLaw from "@/components/dialogs/CookieLaw.vue";

@Component({
  components: { Footer, Header, Modal, CookieLaw }
})
export default class App extends Vue {
  beforeMount() {
    //this.$store.dispatch("checkSession");
  }
}
