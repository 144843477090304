




































import { Component, Vue, Prop } from "vue-property-decorator";
import Card from "@/components/elements/Card.vue";

/**
 * Renders the new tax part
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: {
    Card
  }
})
export default class TaxNew extends Vue {

  /**
   * Returns the card name
   * 
   * @var cardName
   * @type string
   */
  private cardName = 'Aufschlüsselung Steuern';
}
