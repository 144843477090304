















import { Component, Vue } from "vue-property-decorator";

/**
 * Renders the fileinput button
 * 
 * You can add the onchange handler. Example:
 * <FileInput @change="event"></FileInput>
 * 
 * @class
 * @extends Vue
 */
@Component
export default class FileInput extends Vue {
    /**
     * Returns the id of the fileinput
     * 
     * @var id
     * @type string
     */
    private id = '';

    /**
     * Returns the filename
     * 
     * @var filename
     * @type string
     */
    private filename = '';

    /**
     * OnCreated event
     */
    created() {
        this.id = 'fileinput_' + (Date.now() + Math.ceil(Math.random()*9999)).toString();
        this.reset();
    }

    /**
     * Reset the input
     * 
     * @returns void
     */
    reset() {
        this.filename = 'Es wurde keine Datei ausgewählt';
    }

    /**
     * The input files onchange event
     * 
     * @returns void
     */
    change(event:Event) {
        const input = (event.target as any);
        if(input.files.length == 0) return;
        this.setFileName(input.value.split(/(\\|\/)/g).pop());
        this.$emit('change', event);
    }

    /**
     * Sets the filename
     * 
     * @returns void
     */
    setFileName(name:string) {
        this.filename = name;
    }

    /**
     * Gets the filename
     * 
     * @returns void
     */
    getFileName() {
        return this.filename;
    }

}
