


































import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";
import { KEYVALUESTORE,CARDSTORE } from "../../store/globals";
import Card from "./Card.vue";
import { APIURL } from "../../store/globals";
import {
  Steuer
} from "@/store/AutoCalculationFunctions";

/**
 * This type describes a error entry from the validation api call 
 */
type ErrorField = {
    code: string;
    level:string;
    message:string;
    fields: Array<string>;
};

/**
 * This component places an overlay over the window
 * and lets you see the status of a validation.
 * A validation can be triggered through the Validate-Method
 * 
 * Events: 
 * this.$root.$emit('validate', callback) // void callback(result:boolean)
 * 
 * @class
 * @extends Vue
 */
@Component
export default class ValidatorOverlay extends Vue {
  /**
  * Returns if the overlay is expanded or not
  * 
  * @type toggleOpen
  * @var boolean
  */
  private toggleOpen = false;

  /**
  * Returns the status text
  * 
  * @type statusText
  * @var string
  */
  private statusText = '';

  /**
  * Returns the classname for the overlay
  * 
  * @type openClass
  * @var string
  */
  private openClass = '';

  /**
  * Returns a list of ErrorField objects
  * 
  * @type errors
  * @var Array<ErrorField>
  */
  private errors : Array<ErrorField> = [];

  /**
  * Returns the status of the overlay
  * 
  * @type status
  * @var number
  */
  private status = 0;

  /**
  * Returns the visibility of the overlay
  * 
  * @type isShown
  * @var boolean
  */
  private isShown = false;

  /**
  * Updates the status
  * 
  * @method UpdateStatus
  * @param boolean loading If the status is in loading state
  * @param boolean apiError If the status is an api error
  * @return void
  */
  public UpdateStatus(loading = false, apiError = false) {

      if(apiError) {
          this.status = 1;
          this.statusText = 'Überprüfung war nicht möglich';
          return;
      }


      if(loading) {
          this.status = 0;
          this.statusText = 'Wird überprüft...';
          return;
      }

      const errorsLen = this.errors.length;
      this.status = 1;
      if(errorsLen == 0) {
        this.status = 2;
        this.statusText = "Status: Kein Problem festgestellt";
      } else if(errorsLen == 1) {
        this.statusText = "Status: 1 Problem gefunden";
      } else if(errorsLen > 1) {
        this.statusText = "Status: " + errorsLen + " Probleme gefunden";
      }
  }

  /**
  * Checks the errors and set the fields invalid
  * 
  * @method InvalidateFields
  * @return void
  */
  public InvalidateFields() {
      for(const cardName in CARDSTORE) {
          CARDSTORE[cardName].setValid();
      }
      for(const fieldname in KEYVALUESTORE) {
        let i = 0;
        const len = KEYVALUESTORE[fieldname].length;
        for(i; i < len; ++i) {
            KEYVALUESTORE[fieldname][i].instance.setValid();
        }
      }
      for(let i = 0; i < this.errors.length; ++i) {
          const error = this.errors[i];
          for(let j = 0; j < error.fields.length; ++j) {
            const field = error.fields[j];
            if(typeof KEYVALUESTORE[field] != 'undefined') {
                let i = 0;
                const len = KEYVALUESTORE[field].length;
                for(i; i < len; ++i) {
                    KEYVALUESTORE[field][i].instance.setInvalid();
                    const cardInst: Card = KEYVALUESTORE[field][i].instance.getCardInstance();
                    if(cardInst!=null) {
                        cardInst.setInvalid();
                    }
                }
            }
          }
      }
  }
  

  /**
  * Opens a section and go to the error field
  * 
  * @method GoToError
  * @return void
  */
  public GoToError(fieldList:Array<string>) {
      for(const key in fieldList) {
            const fieldName = fieldList[key];
            const kvField = KEYVALUESTORE[fieldName];
            let index = 0;
            if(typeof kvField != 'undefined' && kvField.length > 0) {
                // set the index
                if(kvField[0].instance.cardName == 'Rechnungssteller') {
                    index = this.$store.state.modes.seller;
                }
                if(kvField[0].instance.cardName == 'Rechnungsempfänger') {
                    index = this.$store.state.modes.buyer;
                }
                if(kvField[0].instance.cardName == 'Rechnungsdaten') {
                    index = this.$store.state.modes.invoiceDetail;
                }
                if(kvField[0].instance.cardName == 'Positionen') {
                    index = this.$store.state.modes.positions == 0 ? 1 : 0;
                }

                window.dispatchEvent(new CustomEvent("closeCards"));

                const cardInst: Card = kvField[index].instance.getCardInstance();

                if(cardInst!=null) {
                    cardInst.Open();
                    setTimeout(function() {
                        //const gtmHeader = document.querySelector('.gtm-header') as HTMLElement,
                        //      headerHeight = gtmHeader.getBoundingClientRect().height,
                        const kvPos = (kvField[index].instance.$el as HTMLElement).offsetTop;
                        document.documentElement.scrollTop = kvPos;
                    },300);
                }
                break;
            }
      }
  }

  /**
  * Init
  * 
  * @return void
  */
  public beforeMount() {
    this.UpdateStatus();

    this.$root.$off('validatorOverlayOpen');
    this.$root.$on('validatorOverlayOpen', function(this: ValidatorOverlay) {
        this.Open();
    }.bind(this));

    this.$root.$off('validate'); // remove all listeners to this event to avoid multiple requests
    this.$root.$on('validate', async function(this: ValidatorOverlay, done:Function) {
        this.$root.$emit('disable-download-button');
        await this.Validate();
        const result:boolean = this.IsValid();
        if(result) {
            this.$root.$emit('enable-download-button');
        }
        done(result);
    }.bind(this));
  }

  /**
  * Toggles the overlay open and closed
  * 
  * @return void
  */
  public Toggle() {
      if(this.toggleOpen) {
          this.Close();
      } else {
          this.Open();
      }
  }

  /**
  * Returns whether the data is valid or not
  * 
  * @return boolean
  */
  public IsValid() {
      let gotErrorInIt = false;
      for(let i = 0; i < this.errors.length; ++i) {
          if(this.errors[i].level == 'error') {
              gotErrorInIt = true;
              break;
          }
      }
      if(!gotErrorInIt) return true;
      return this.errors.length==0;
  }

  /**
  * Validates against the document data
  * 1. Updates the status
  * 2. Set possible fields invalid in the form
  * 
  * @return void
  */
  public async Validate() {
    this.UpdateStatus(true);

    // fixes 'NaN' error 
    // when vat is empty it will recalculate the vat and set it
    Steuer(this.$store.state.data, this.$store);
    
    if(!this.isShown) this.isShown = true;

    const res = await axios
      .post(APIURL + `/xrechnung/validate/${this.$store.state.format}`, this.$store.state.data),
      data = res.data;
      console.log(data);

    if(data == 0) {
        this.UpdateStatus(false,true);
    } else {
        this.errors = data.errors;
        this.UpdateStatus(false);
    }
    this.InvalidateFields();

  }

  /**
  * Opens the overlay
  * 
  * @return void
  */
  public Open() {
    this.toggleOpen = true;
    this.openClass = 'open';
  }

  /**
  * Closes the overlay
  * 
  * @return void
  */
  public Close() {
    this.toggleOpen = false;
    this.openClass = '';
  }
}
