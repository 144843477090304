








































import { Component, Vue } from "vue-property-decorator";
import LoginDialog from "@/components/dialogs/LoginDialog.vue";
import Dialog from "@/components/elements/Dialog.vue";
import Modal from "@/components/elements/Modal.vue";
import GButton from "@/components/elements/GButton.vue";

/**
 * Handles the header interactions
 * 
 * Events:
 * - invalidSession = this.$root.$emit('invalidSession')
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: { Modal, Popup: Dialog, LoginPopup: LoginDialog, GButton }
})
export default class Header extends Vue {

  /**
   * Returns the visibility state of the login popup
   * 
   * @var loginPopupOpen
   * @type boolean
   */
  private loginPopupOpen = false;

  /**
   * Returns the classname for the gtm-floating element.
   * Set it to hide to hide the header
   * 
   * @var floatingClass
   * @type string
   */
  private floatingClass = '';

  /**
   * Returns whether the user scrolls above the header height or not
   * 
   * 1 = above
   * 0 = below
   * 
   * @var aboveOrBelow
   * @type number
   */
  private aboveOrBelow = 0;

  /**
   * Returns the header height
   * 
   * @var headerHeight
   * @type number
   */
  private headerHeight = 0;

  /**
   * Creates the invalidSession event and adds the scrolling functionality
   * 
   * @returns void
   */
  mounted() {
    this.$root.$off('invalidSession');
    this.$root.$on('invalidSession', function(this : Header) {
      (this.$refs.modal as Modal).Open({
        title: "Die Session ist ungültig.",
        message : "Bitte melden Sie sich erneut an.",
        type: "confirm"
      });
      this.$router.push('/');
    }.bind(this));

    document.removeEventListener('scroll', this.onScroll);
    document.addEventListener('scroll', this.onScroll ,false);

    const rect = (this.$refs.header as HTMLElement).getBoundingClientRect();
    this.headerHeight = rect.height;
  }

  /**
   * The event onscroll event handles the whether the user scrolls below or above the header height
   * 
   * @returns void
   */
  onScroll() {
    if(document.documentElement.scrollTop > this.headerHeight && this.aboveOrBelow == 0) {
      this.aboveOrBelow = 1;
      this.hide();
    } 
    if(document.documentElement.scrollTop < this.headerHeight && this.aboveOrBelow == 1) {
      this.aboveOrBelow = 0;
      this.show();
    }
  }

  /**
   * Hides the header
   * 
   * @returns void
   */
  hide() {
    const header = (this.$refs.header as HTMLElement);
    header.style.opacity = '0';
    setTimeout(function(this:Header) {
      this.floatingClass = 'hide';
    }.bind(this),500);
  }

  /**
   * Shows the header
   * 
   * @returns void
   */
  show() {
      const header = (this.$refs.header as HTMLElement);
      this.floatingClass = '';
      setTimeout(function(this:Header) {
        header.style.opacity = '1';
      }.bind(this),100);
  }

  /**
   * Opens the login popup
   * 
   * @returns void
   */
  openLogin() {
    this.loginPopupOpen = true;
  }

  /**
   * Closes the login popup
   * 
   * @returns void
   */
  loginPopupClosed() {
    this.loginPopupOpen = false;
  }

  /**
   * Logouts the user
   * 
   * @returns void
   */
  async logout() {
    (this.$refs.modal as Modal).Open({
      title: "Logout erfolgreich",
      type: "confirm"
    });

    await this.$store.dispatch("logout");

    // route to startpage automaticly
    if (this.$route.path !== '/') {
      this.$router.push('/');
    }
  }
}
