/**
 * Returns a object of keyvalue definitions with its index is the id
 * 
 * @var KEYVALUESTORE
 * @type object
 */
export const KEYVALUESTORE : Record<string, any> = {};

/**
 * Returns a object of card definitions with its index is the name
 * 
 * @var CARDSTORE
 * @type object
 */
 export const CARDSTORE : Record<string, any> = {};

 /**
 * Returns if the info was shown or not
 * 
 * @var infoWasShown
 * @type boolean
 */
 window.infoWasShown = false;

 /**
 * Returns the url to the api
 * 
 * @var APIURL
 * @type string
 */
 //export const APIURL = 'https://devapi.xrechnung.new';
 export const APIURL = 'https://api.xrechnung.new';


/**
 * Returns a object of country key-pairs
 * 
 * @var COUNTRYLIST
 * @type object
 */
 export const COUNTRYLIST = JSON.parse("{\"DE\":\"Deutschland\",\"AX\":\"Åland\",\"AL\":\"Albanien\",\"AD\":\"Andorra\",\"BE\":\"Belgien\",\"BA\":\"Bosnien-Herzegowina\",\"BG\":\"Bulgarien\",\"DK\":\"Dänemark\",\"EE\":\"Estland\",\"FO\":\"Färöer\",\"FI\":\"Finnland\",\"FR\":\"Frankreich\",\"GI\":\"Gibraltar\",\"GR\":\"Griechenland\",\"GB\":\"Großbritannien\",\"GG\":\"Guernsey\",\"IE\":\"Irland\",\"IS\":\"Island\",\"IM\":\"Isle of Man\",\"IT\":\"Italien\",\"JE\":\"Jersey\",\"KZ\":\"Kasachstan\",\"XK\":\"Kosovo\",\"HR\":\"Kroatien\",\"LV\":\"Lettland\",\"LI\":\"Liechtenstein\",\"LT\":\"Litauen\",\"LU\":\"Luxemburg\",\"MT\":\"Malta\",\"MK\":\"Mazedonien\",\"MD\":\"Moldawien\",\"MC\":\"Monaco\",\"ME\":\"Montenegro\",\"NL\":\"Niederlande\",\"NO\":\"Norwegen\",\"AT\":\"Österreich\",\"PL\":\"Polen\",\"PT\":\"Portugal\",\"RO\":\"Rumänien\",\"RU\":\"Russische Föderation\",\"SM\":\"San Marino\",\"SE\":\"Schweden\",\"CH\":\"Schweiz\",\"RS\":\"Serbien\",\"SK\":\"Slowakische Republik\",\"SI\":\"Slowenien\",\"ES\":\"Spanien\",\"SJ\":\"Svalbard + Jan Mayen\",\"CZ\":\"Tschechische Republik\",\"TR\":\"Türkei\",\"UA\":\"Ukraine\",\"HU\":\"Ungarn\",\"VA\":\"Vatikanstadt\",\"BY\":\"Weißrussland (Belarus)\",\"CY\":\"Zypern\"}");

/**
 * Fill the fields with example values for testing
 * 
 * @return void
 */
window.FillExampleValues = function() {
    console.log(99999,KEYVALUESTORE)
    KEYVALUESTORE['BT-29'][0].instance.value = '100-12345';                     //Ihre Liferantennummer
    KEYVALUESTORE['BT-27'][0].instance.value = 'gotomaxx GmbH';                 //Name
    KEYVALUESTORE['BT-28'][0].instance.value = 'gotomaxx';                      //Handelsname
    KEYVALUESTORE['BT-35'][0].instance.value = 'Musterstraße 1';                //Adresszeile
    KEYVALUESTORE['BT-36'][0].instance.value = 'Gebäude';                       //Adresszeile 2
    KEYVALUESTORE['BT-162'][0].instance.value = 'Etage 4';                      //Adresszeile 3
    KEYVALUESTORE['BT-38'][0].instance.value = '12345';                         //PLZ
    KEYVALUESTORE['BT-37'][0].instance.value = 'Berlin';                        //Ort
    KEYVALUESTORE['BT-31'][0].instance.value = 'DE398517849';                   //USt-id
    KEYVALUESTORE['BT-32'][0].instance.value = '00 14 0 251 1234 7';            //Steuernummer
    KEYVALUESTORE['BT-85'][0].instance.value = 'Max Mustermann';                //Name des Kontoinhabers
    KEYVALUESTORE['BT-84'][0].instance.value = 'DE45 1000 0000 0012 3456 78';   //IBAN
    KEYVALUESTORE['BT-86'][0].instance.value = '12341212123';                   //BIC
    KEYVALUESTORE['BT-41'][0].instance.value = 'Max Mustermann';                //Ansprechpartner Name
    KEYVALUESTORE['BT-42'][0].instance.value = '+49 225 123456789';             //Ansprechpartner Telefon
    KEYVALUESTORE['BT-43'][0].instance.value = 'max.mustermann@gotomaxx.com';   //Ansprechpartner E-Mail
    //Rechnungsempfänger
    KEYVALUESTORE['BT-46'][0].instance.value = '184541';                        //Kundennummer
    KEYVALUESTORE['BT-48'][0].instance.value = 'DE398517849';                   //USt-id
    KEYVALUESTORE['BT-56'][0].instance.value = 'Max Mustermann';                //Ansprechpartner Name
    KEYVALUESTORE['BT-57'][0].instance.value = '+49 225 123456789';             //Ansprechpartner Telefon
    KEYVALUESTORE['BT-58'][0].instance.value = 'max.mustermann@gotomaxx.com';   //Ansprechpartner E-Mail
    //Rechnungsadresse
    KEYVALUESTORE['BT-44'][0].instance.value = 'Zentrale';                      //Name
    KEYVALUESTORE['BT-50'][0].instance.value = 'Musterstraße 1a';               //Adresszeile
    KEYVALUESTORE['BT-51'][0].instance.value = 'Gebäude 2';                     //Adresszeile 2
    KEYVALUESTORE['BT-163'][0].instance.value = 'Etage 4';                      //Adresszeile 3
    KEYVALUESTORE['BT-53'][0].instance.value = '12345';                         //PLZ
    KEYVALUESTORE['BT-52'][0].instance.value = 'Berlin';                        //Ort
    //Lieferadresse
    KEYVALUESTORE['BT-70'][0].instance.value = 'Lager';                         //Name
    KEYVALUESTORE['BT-75'][0].instance.value = 'Beispielweg 9';                 //Adresszeile
    KEYVALUESTORE['BT-76'][0].instance.value = 'Gebäude 1';                     //Adresszeile 2
    KEYVALUESTORE['BT-165'][0].instance.value = 'Erdgeschoss';                  //Adresszeile 3
    KEYVALUESTORE['BT-78'][0].instance.value = '12345';                         //PLZ
    KEYVALUESTORE['BT-77'][0].instance.value = 'Berlin';                        //Ort
    //Rechnungsdaten
    KEYVALUESTORE['BT-1'][0].instance.value = '1234567';                        //Belegnummer
    KEYVALUESTORE['BT-2'][0].instance.value = '2021-05-04';                     //Belegdatum
    KEYVALUESTORE['BT-13'][0].instance.value = '125551';                        //Bestellnummer
    KEYVALUESTORE['BT-72'][0].instance.value = '2021-05-04';                    //Lieferdatum
    KEYVALUESTORE['BT-10'][0].instance.value = '05 1 58 004 - 11023 - 45';      //Leitweg-ID
    KEYVALUESTORE['BT-22'][0].instance.value = 'Beispielhafte Notiz';           //Notiz zum Beleg
    KEYVALUESTORE['BT-11'][0].instance.value = '0005';                          //Projektnummer
    KEYVALUESTORE['BT-12'][0].instance.value = '1000-4321';                     //Vertragsnummer
    KEYVALUESTORE['BT-14'][0].instance.value = '220/1100';                      //Auftragsnummer
    KEYVALUESTORE['BT-73'][0].instance.value = '2021-05-04';                    //Rechnungszeitraum Start
    KEYVALUESTORE['BT-74'][0].instance.value = '2021-05-05';                    //Rechnungszeitraum Ende
    KEYVALUESTORE['BT-25'][0].instance.value = '125550';                        //Nummer vorausgegangerner Rechnung
    KEYVALUESTORE['BT-26'][0].instance.value = '2021-05-05';                    //Datum vorausgegangerner Rechnung
    KEYVALUESTORE['BT-120'][0].instance.value = 'Hinweise auf Ausnahmen...';    //Ausnahme MWST
    //Zahlungsdaten
    KEYVALUESTORE['BT-83'][0].instance.value = '1234567';                       //Zahlungsreferenz
    KEYVALUESTORE['BT-9'][0].instance.value = '2021-05-04';                     //Zahlbar bis
    KEYVALUESTORE['BT-20'][0].instance.value = 'Hinweise auf Konditionen...';   //Zahlungskonditionen
    KEYVALUESTORE['BT-82'][0].instance.value = 'Freitext';                      //Zahlungsmittel
  
}