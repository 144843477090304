



































































import { Component, Vue } from "vue-property-decorator";
import Card from "@/components/elements/Card.vue";
import KeyValue from "@/components/elements/KeyValue.vue";

/**
 * Renders the buyer part
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: {
    Card,
    KeyValue
  }
})
export default class Buyer extends Vue {
  /**
   * Returns the card name
   * 
   * @var cardName
   * @type string
   */
  private cardName = 'Rechnungsempfänger';

  /**
   * Changes the view mode
   * 
   * @param number mode The view mode
   * @returns void
   */
  modeChanged(mode:number) {
    this.$store.state.modes.buyer = mode;
    if(mode==0) {
        // buyer
        this.$store.state.data.BUYER['BT-46'] = '';
        this.$store.state.data.BUYER['BT-48'] = '';
        this.$store.state.data.BUYER['BT-56'] = '';
        this.$store.state.data.BUYER['BT-57'] = '';
        this.$store.state.data.BUYER['BT-58'] = '';
        this.$store.state.data.BUYER['BT-51'] = '';
        this.$store.state.data.BUYER['BT-163'] = '';
        this.$store.state.data.BUYER['BT-70'] = '';
        this.$store.state.data.BUYER['BT-75'] = '';
        this.$store.state.data.BUYER['BT-76'] = '';
        this.$store.state.data.BUYER['BT-165'] = '';
        this.$store.state.data.BUYER['BT-78'] = '';
        this.$store.state.data.BUYER['BT-77'] = '';
        this.$store.state.data.BUYER['BT-80'] = 'DE';
    }
  }

  /**
   * Determines the right name based on mode
   */
  data() {
    return {
      cardNameRes : function(this: Buyer) {
        if(this.$store.state.mode==0) {
          return 'Kundendaten';
        } else {
          return this.cardName;
        }
      }.bind(this)
    }
  }

}
