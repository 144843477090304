













































































import { Component, Vue } from "vue-property-decorator";
import Card from "@/components/elements/Card.vue";
import KeyValue from "@/components/elements/KeyValue.vue";
import { KEYVALUESTORE } from "../../store/globals";

/**
 * Renders the seller part
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: {
    Card,
    KeyValue
  }
})
export default class Seller extends Vue {
  
  /**
   * Returns the card name
   * 
   * @var cardName
   * @returns string
   */
  private cardName = 'Rechnungssteller';

  created() {

    this.$root.$off('sellerCardOpenFocus');
    this.$root.$on('sellerCardOpenFocus', function(this:Seller,scrollTo:boolean) {
      
      (this.$refs.card as Card).Open(scrollTo);
      setTimeout(function(this:Seller) {
        (KEYVALUESTORE['BT-27'][0].instance as KeyValue).focusInput();
      }.bind(this),1500);
    }.bind(this));

  }

  /**
   * Changes the view mode
   * 
   * @param number mode The view mode
   * @returns void
   */
  modeChanged(mode:number) {
    this.$store.state.modes.seller = mode;
    if(mode==0) {
        // seller
        this.$store.state.data.SELLER['BT-29'] = '';
        this.$store.state.data.SELLER['BT-28'] = '';
        this.$store.state.data.SELLER['BT-36'] = '';
        this.$store.state.data.SELLER['BT-162'] = '';
    }
  }

  /**
   * Determines the right name based on
   */
  data() {
    return {
      cardNameRes : function(this: Seller) {
        if(this.$store.state.mode==0) {
          return 'Ihre Daten';
        } else {
          return this.cardName;
        }
      }.bind(this)
    }
  }
}
