









































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import Dialog from "@/components/elements/Dialog.vue";
import axios from "axios";
import Modal from "@/components/elements/Modal.vue";
import { APIURL,COUNTRYLIST } from "../../store/globals";

/**
 * Renders the register dialog
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: {
    Dialog,
    Modal
  }
})
export default class RegisterDialog extends Vue {
  @Prop() private isOpen!: boolean;

  // form account info
  /**
   * Returns the email value
   * 
   * @var emailModel
   * @type string
   */
  private emailModel = "";

  /**
   * Returns the password value
   * 
   * @var passwordModel
   * @type string
   */
  private passwordModel = "";

  /**
   * Returns the password repeat value
   * 
   * @var password2Model
   * @type string
   */
  private password2Model = "";

  // form account meta
  /**
   * Returns the company name value
   * 
   * @var companyName
   * @type string
   */
  private companyName = "";

  /**
   * Returns the ustid value
   * 
   * @var ustid
   * @type string
   */
  private ustid = "";

  /**
   * Returns the contact person name
   * 
   * @var contactPerson
   * @type string
   */
  private contactPerson = "";

  /**
   * Returns the phone value
   * 
   * @var phone
   * @type string
   */
  private phone = "";

  /**
   * Returns the street value
   * 
   * @var street
   * @type string
   */
  private street = "";

  /**
   * Returns the zipcode value
   * 
   * @var zipcode
   * @type string
   */
  private zipcode = "";

  /**
   * Returns the place value
   * 
   * @var place
   * @type string
   */
  private place = "";

  /**
   * Returns the country value
   * 
   * @var country
   * @type string
   */
  private country = "";

  /**
   * Returns whether the agreement was accepted or not
   * 
   * @var hasAcceptedAgreement
   * @type boolean
   */
  private hasAcceptedAgreement = false;

  /**
   * Returns whether the editing is active or not
   * 
   * @var isEditingInfos
   * @type boolean
   */
  private isEditingInfos = false;

  /**
   * Returns a object of countries
   * 
   * @var countrylist
   * @type object
   */
  private countrylist = {};

  /**
   * Gets called on creation
   * 
   * @returns void
   */
  created() {
    this.countrylist = COUNTRYLIST;
    this.$watch('isOpen', function(newVal, oldVal) {
      if(newVal) {
        this.open();
      }
    });
  }

  /**
   * Closes the dialog
   * 
   * @returns void
   */
  private close() {
    this.$emit("close");
  }

  /**
   * Opens the dialog
   * 
   * @returns void
   */
  private open() {
    this.resetForm();
  }

  /**
   * Resets the form
   * 
   * @returns void
   */
  private resetForm() {
    this.emailModel = 
      this.passwordModel = 
      this.password2Model =
      "";
      this.hasAcceptedAgreement = false;
      this.place = this.$store.state.data.SELLER["BT-37"];
      this.companyName = this.$store.state.data.SELLER["BT-27"];
      this.ustid = this.$store.state.data.SELLER["BT-31"];
      this.street = this.$store.state.data.SELLER["BT-35"];
      this.zipcode = this.$store.state.data.SELLER["BT-38"];
      this.country = this.$store.state.data.SELLER["BT-40"];
      this.contactPerson = this.$store.state.data.SELLER["BT-41"];
      this.phone = this.$store.state.data.SELLER["BT-42"];
  }

  /**
   * Sends a sign up request
   * 
   * @returns void
   */
  private SignUp() {

    const form = this.$refs.registerForm as HTMLFormElement;
    if(!form.checkValidity()) {
      return;
    }
    if(this.emailModel.length==0) {
      (this.$refs.modal as Modal).Open({
        title: "Registrierung fehlgeschlagen",
        message: "Es muss eine E-Mail-Adresse angegeben werden.",
        type: "confirm"
      });
      return;
    }
    if(this.passwordModel.length == 0 || this.password2Model.length == 0) {
      (this.$refs.modal as Modal).Open({
        title: "Registrierung fehlgeschlagen",
        message: "Die Passwörter dürfen nicht leer sein.",
        type: "confirm"
      });
      return;
    }
    if (this.passwordModel !== this.password2Model) {
      (this.$refs.modal as Modal).Open({
        title: "Registrierung fehlgeschlagen",
        message: "Das passwort stimmt nicht überein",
        type: "confirm"
      });
      return;
    }

    if(typeof this.contactPerson == 'undefined') {
      this.contactPerson = '';
    }
    const fullname = this.contactPerson,
        split = fullname.split(' '),
        prename = split[0];
    let name = '';
    if(split.length>1) {
      name = split[split.length - 1];
    }

    axios
      .post(APIURL + "/register", {
        email: this.emailModel,
        pwd: this.passwordModel,
        company: this.companyName,
        ustid: this.ustid,
        uri: location.href,
        companyType: "GmbH",
        street: this.street,
        zip: this.zipcode,
        city: this.place,
        country: this.country,
        salutation: "0",
        title: "",
        prename: prename,
        name: name,
        tel: this.phone,
        iban: ''
      })
      .then(async function(this:RegisterDialog, response: { data:number; }) {
        if (response.data == 0) {
          (this.$refs.modal as Modal).Open({
            title: "Registrierung erfolgreich",
            message: "Ihr Account wurde erstellt. <br> Sie erhalten in kürze eine Bestätigungsmail.",
            type: "confirm",
            callback: function(this:RegisterDialog) {
              this.close();
            }.bind(this)
          });
        } else if(response.data == 1) {
          (this.$refs.modal as Modal).Open({
            title: "Registrierung fehlgeschlagen",
            message: "Eine Konto besteht bereits mit dieser E-Mail-Adresse.",
            type: "confirm"
          });
        } else {
          (this.$refs.modal as Modal).Open({
            title: "Registrierung fehlgeschlagen",
            message: "Eine Registration war nicht möglich. <br> Bitte versuchen Sie es später erneut.",
            type: "confirm"
          });
        }
      }.bind(this))
      .catch(function(this:RegisterDialog,err:{message:string;}) {
        console.warn(err);
        (this.$refs.modal as Modal).Open({
          title: "Registrierung fehlgeschlagen",
          message: "Der Server ist derzeit leider nicht erreichbar. <br> Bitte versuchen Sie es später erneut. <br> Fehler: " + err.message,
          type: "confirm"
        });
      }.bind(this));
  }
}
