





















import { Component, Vue } from "vue-property-decorator";
import Card from "@/components/elements/Card.vue";
import KeyValue from "@/components/elements/KeyValue.vue";

/**
 * Renders the totals part
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: { KeyValue, Card }
})
export default class Totals extends Vue {
  /**
   * Returns the card name
   * 
   * @var cardName
   * @type string
   */
  private cardName = 'Gesamtsummen';
}
