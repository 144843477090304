

































import { Component, Vue, Prop } from "vue-property-decorator";
import Dialog from "@/components/elements/Dialog.vue";
import Modal from "@/components/elements/Modal.vue";

/**
 * Renders the info dialog
 * 
 * @class
 * @extends Vue
 */
@Component({
  components: { Modal, Dialog }
})
export default class InfoDialog extends Vue {
  /**
   * Returns whether the dialog is open or not
   * 
   * @var isOpen
   * @type boolean
   */
  @Prop() private isOpen!: boolean;

  /**
   * Closes the dialog
   * 
   * @returns void
   */
  private close() {
    this.$emit("close");
  }
}
