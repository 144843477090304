import { render, staticRenderFns } from "./PageFrame.vue?vue&type=template&id=c65c6086&scoped=true&"
import script from "./PageFrame.vue?vue&type=script&lang=ts&"
export * from "./PageFrame.vue?vue&type=script&lang=ts&"
import style0 from "./PageFrame.vue?vue&type=style&index=0&id=c65c6086&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c65c6086",
  null
  
)

export default component.exports